import PropTypes from 'prop-types'

function AmpYoutube({ width, height, videoId }) {
    return (
        videoId && (
            <amp-youtube
                data-videoid={videoId}
                layout="responsive"
                width={width.toString()}
                height={height.toString()}
            />
        )
    )
}

AmpYoutube.propTypes = {
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    videoId: PropTypes.string
}

AmpYoutube.defaultProps = {
    width: 480,
    height: 270,
    videoId: undefined
}
export default AmpYoutube
