import { toRem } from '../../helpers'

/* eslint-disable quote-props, max-len */
const style = ({ theme, color, variant, size }) => {
    const config = {
        size: {
            variant: {
                normal: {
                    button: {
                        marginX: toRem(5)
                    },
                    icon: {
                        size: toRem(24),
                        marginX: toRem(5)
                    },
                    value: toRem(12),
                    title: toRem(16)
                },
                large: {
                    button: {
                        marginX: toRem(8)
                    },
                    icon: {
                        size: toRem(48),
                        marginX: toRem(8)
                    },
                    value: toRem(18),
                    title: toRem(32)
                },
                xl: {
                    button: {
                        marginX: toRem(10)
                    },
                    icon: {
                        size: toRem(64),
                        marginX: toRem(10)
                    },
                    value: toRem(24),
                    title: toRem(44)
                }
            }
        },
        button: {
            variant: {
                share: {
                    value: {
                        display: 'none'
                    }
                },
                // @INFO: value offset has to be a relative unit so we don't have to specify for each size variant
                comment: {
                    value: {
                        display: 'flex',
                        offsetTop: '-2%',
                        offsetLeft: '-1%'
                    }
                },
                review: {
                    value: {
                        display: 'flex',
                        offsetTop: '-9%',
                        offsetLeft: '-3%'
                    }
                },
                score: {
                    value: {
                        display: 'flex',
                        offsetTop: '-2%',
                        offsetLeft: '-8%'
                    }
                },
                advice: {
                    value: {
                        display: 'flex',
                        offsetTop: '-2%',
                        offsetLeft: '-1%'
                    }
                },
                answer: {
                    value: {
                        display: 'none'
                    }
                }
            }
        }
    }
    return {
        display: 'flex',
        alignItems: 'center',
        marginRight: config?.size?.variant[size]?.button?.marginX || toRem(5),
        '& .metaButton': {
            '&_inner': {
                display: 'flex',
                alignItems: 'center'
            },
            '&_icon': {
                position: 'relative',
                color: theme.colors.meta.button[color]?.icon || theme.colors.devWarning,
                lineHeight: 0,
                fontSize: config?.size?.variant[size]?.icon?.size || toRem(32),
                height: config?.size?.variant[size]?.icon?.size || toRem(32),
                marginRight: config?.size?.variant[size]?.icon?.marginX || toRem(5),
                path: {
                    fill: 'currentColor'
                }
            },
            '&_iconValue': {
                position: 'absolute',
                width: '100%',
                height: '100%',
                top: 0,
                left: 0,
                display: config?.button?.variant[variant]?.value?.display || 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontWeight: theme.typography.fontWeight.bold,
                fontSize: config?.size?.variant[size]?.value || toRem(12),
                lineHeight: config?.size?.variant[size]?.value || toRem(12),
                marginLeft: config?.button?.variant[variant]?.value?.offsetLeft || '-1%',
                marginTop: config?.button?.variant[variant]?.value?.offsetTop || '-2%',
                color: theme.colors.meta.button[color]?.value || theme.colors.devWarning
            },
            '&_title': {
                marginTop: '-2%', // slight font adjustment
                fontWeight: theme.typography.fontWeight.bold,
                fontSize: config?.size?.variant[size]?.title,
                color: theme.colors.meta.button[color]?.title || theme.colors.devWarning
            }
        }
    }
}
export default style
