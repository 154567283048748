import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { useInnerHtml } from '@hmn/rtl-web-core/hooks'

import styles from './Signature.style'

const HighlightedSignatureStyled = styled.div(props => ({ ...styles(props) }))

function HighlightedSignature({ className, author, authorDesc, ...rest }) {
    const authorHtmlProps = useInnerHtml(author)
    const authorDescHtmlProps = useInnerHtml(authorDesc)

    if (!author) {
        return null
    }

    return (
        <HighlightedSignatureStyled className={className} {...rest}>
            <div className="sectionSignature_author">
                <span {...authorHtmlProps} />
            </div>
            {authorDesc && (
                <div className="sectionSignature_authorDesc">
                    <i {...authorDescHtmlProps} />
                </div>
            )}
        </HighlightedSignatureStyled>
    )
}

HighlightedSignature.propTypes = {
    className: PropTypes.string,
    author: PropTypes.string,
    authorDesc: PropTypes.string
}

HighlightedSignature.defaultProps = {
    className: undefined,
    author: undefined,
    authorDesc: undefined
}

export default HighlightedSignature
