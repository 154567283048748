import { useMemo } from 'react'

type UsePlayerOptionsProps = {
    title: string
    description: string
    url: string
    thumbnail: string | Record<string, string>
    autoplay: boolean
    sticky?: boolean
    fill?: boolean
    fluid?: boolean
    inViewAutoplay?: boolean
    muted?: boolean
}
export const usePlayerOptions = ({
    title,
    description,
    url,
    thumbnail,
    autoplay,
    sticky = true,
    fill = false,
    fluid = true,
    inViewAutoplay = false,
    muted = true
}: UsePlayerOptionsProps) => {
    const options = useMemo(() => {
        const out = {
            sticky,
            autoplay,
            muted,
            controls: true,
            fluid,
            fill,
            title,
            description,
            sources: [url],
            inView: {
                sticky,
                autoplay: inViewAutoplay,
                rootMargin: '-100px 0px -200px'
            },
            poster: typeof thumbnail === 'string' ? thumbnail : thumbnail?.original_url
        }

        return out
    }, [title, description, url, thumbnail, autoplay, muted, sticky, fill, fluid, inViewAutoplay])

    return options
}
