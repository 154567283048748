import { toRem, toRems } from '../../../../helpers/theme'

const config = {
    xs: {
        blockVerticalMargin: 20
    },
    md: {
        blockVerticalMargin: 25
    }
}

/* eslint-disable quote-props, no-dupe-keys */
const style = ({ theme }) => ({
    '& .article': {
        '&Content': {
            h1: {
                margin: toRems([0, 0, 30, 0])
            },
            '& > * + h1': {
                marginTop: toRem(40)
            },
            h2: {
                margin: toRems([0, 0, 30, 0])
            },
            '& > * + h2': {
                marginTop: toRem(40)
            },
            h3: {
                margin: toRems([0, 0, 20, 0])
            },
            '& > * + h3': {
                marginTop: toRem(40)
            },
            h4: {
                margin: toRems([0, 0, 20, 0])
            },
            '& > * + h4': {
                marginTop: toRem(32)
            },
            '& h5, h6': {
                margin: toRems([0, 0, 4, 0])
            },
            '& > * + h5, > * + h6': {
                marginTop: toRem(20)
            },
            img: {
                margin: toRems([10, 0]),
                width: '100%',
                maxWidth: '100%',
                height: 'auto',
                [theme.breakpoints.up('md')]: {
                    margin: toRems([42, 0])
                }
            },
            p: {
                marginBottom: toRem(config.xs.blockVerticalMargin),
                [theme.breakpoints.up('md')]: {
                    marginBottom: toRem(config.md.blockVerticalMargin)
                }
            },
            a: {
                color: theme.colors.link.primary.text.default,
                borderBottom: `1px solid ${theme.colors.link.primary.border.default}`,
                '&:focus': {
                    color: theme.colors.link.primary.text.focus,
                    borderBottom: 'none',
                    outline: `1px dashed ${theme.colors.link.primary.border.focus}`
                },
                '&:hover': {
                    color: theme.colors.link.primary.text.hover,
                    borderBottom: `1px solid ${theme.colors.link.primary.border.hover}`
                },
                '&:active': {
                    color: theme.colors.link.primary.text.active,
                    borderBottom: `1px solid ${theme.colors.link.primary.border.active}`
                }
            },
            ol: {
                margin: toRems([config.xs.blockVerticalMargin * 1.5, 0]),
                counterReset: 'ol-list-counter',
                [theme.breakpoints.up('md')]: {
                    margin: toRems([config.md.blockVerticalMargin * 1.8, 0])
                },
                '> li': {
                    counterIncrement: 'ol-list-counter',
                    margin: toRems([0, 0, 18, 0]),
                    padding: toRems([0, 0, 0, 30]),
                    [theme.breakpoints.up('md')]: {
                        margin: toRems([0, 0, 26, 0])
                    },
                    '&::before': {
                        content: 'counter(ol-list-counter) "."',
                        display: 'block',
                        position: 'relative',
                        maxWidth: 0,
                        maxHeight: 0,
                        left: toRem(-30),
                        color: theme.colors.list.decimal.default
                    },
                    ul: {
                        li: {
                            '&::before': {
                                content: '""'
                            }
                        }
                    },
                    'ul, ol': {
                        flexBasis: '100%',
                        counterReset: 'ol-list-counter'
                    },
                    '&:last-of-type': {
                        marginBottom: 0
                    }
                }
            },
            ul: {
                margin: toRems([config.xs.blockVerticalMargin * 1.5, 0]),
                [theme.breakpoints.up('md')]: {
                    margin: toRems([config.md.blockVerticalMargin * 1.8, 0])
                },
                '> li': {
                    display: 'flex',
                    flexWrap: 'wrap',
                    position: 'relative',
                    margin: toRems([0, 0, 18, 0]),
                    paddingLeft: toRem(30),
                    [theme.breakpoints.up('md')]: {
                        margin: toRems([0, 0, 26, 0]),
                        paddingLeft: toRem(50)
                    },
                    '&::before': {
                        content: '""',
                        position: 'absolute',
                        top: toRem(10),
                        left: 0,
                        marginRight: toRem(15),
                        width: toRem(20),
                        height: 2,
                        background: theme.colors.list.dash.default,
                        [theme.breakpoints.up('md')]: {
                            top: toRem(14),
                            width: toRem(35)
                        }
                    },
                    'ul, ol': {
                        flexBasis: '100%'
                    },
                    '&:last-of-type': {
                        marginBottom: 0
                    }
                }
            }
        }
    }
})

export default style
