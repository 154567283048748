import { CommonSofascoreEmbed } from '@hmn/rtl-web-core/components/HtmlContent'
import { EmbedVideo } from '@hmn/rtl-web-core/components/HtmlContent/HtmlContent.style'

import { FacebookEmbed } from '../FacebookItem/FacebookItem.component'

function Rtl(attributes) {
    const {
        url,
        type, // description,
        ...attrs
    } = attributes

    // @TODO: revisit if type can be image
    if (type === 'video') {
        return (
            <EmbedVideo {...attrs}>
                <iframe style={{ border: '0' }} title={url} src={url} />
            </EmbedVideo>
        )
    }
    return null
}
const embeds = new Map()
embeds.set('rtl', attributes => <Rtl {...attributes} />)
embeds.set('facebook', attributes => <FacebookEmbed {...attributes} />)
embeds.set('sofascore', attributes => (
    <CommonSofascoreEmbed {...attributes} placeholder="/images/placeholder/zena_logo.png" />
))
export { embeds }
