import { useMemo } from 'react'
import styled from '@emotion/styled'
import { useAmp } from 'next/amp'
import dynamic from 'next/dynamic'
import PropTypes from 'prop-types'

import { AmpVideo } from '@hmn/rtl-web-core/components/Amp'
import { AntiClsFrame } from '@hmn/rtl-web-core/components/AntiClsFrame'
import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'
import { videoAdsPropType } from '@hmn/rtl-web-core/components/VideoJsPlayer/VideoJsPlayer.component'
import { useInnerHtml } from '@hmn/rtl-web-core/hooks'

import { Graphic, graphicFillVariants, graphicVariants, Triangle } from '../../../Decoration/components'
import { HeadingLabel } from '../../../Heading/components/Label'
import { HtmlContent } from '../../../HtmlContent'
import styles from './Content.style'

const VideoJsPlayer = dynamic(() => import('./VideoJs.player').then(mod => mod.VideoJsPlayer), { ssr: false })

const ContentStyled = styled.section(props => ({ ...styles(props) }))

const getPrerollAmpTag = (isAmp, ads) => {
    if (!isAmp) {
        return ''
    }

    const codes = ads?.[0]?.codes?.split('/') // "i/net_hr:video/554/1"
    if (!codes?.length) {
        return ''
    }
    const sitePath = codes[1]?.split(':')
    return `https://core.iprom.net/vast?sitePath=["RTL", "${sitePath?.[0]}", "${sitePath?.[1]}"]&zones=[${codes[2]}]&count=${codes[3]}&time=[TIMESTAMP]`
}

function Content({
    className,
    label,
    subtitle,
    title,
    titleTagComponent: TitleTag,
    lead,
    url,
    thumbnail,
    isEmbed,
    isHead,
    id,
    ads,
    autoplay,
    inViewAutoplay,
    ...rest
}) {
    const isAmp = useAmp()
    const labelHtmlProps = useInnerHtml(label)
    const subtitleHtmlProps = useInnerHtml(subtitle)
    const titleHtmlProps = useInnerHtml(title)
    const prerollAmpTag = useMemo(() => getPrerollAmpTag(isAmp, ads), [isAmp, ads])

    // const [isFixed, setIsFixed] = useState(false)

    if (!url) {
        return null
    }

    return (
        <ContentStyled className={className} isHead={isHead} {...rest}>
            <div className="videoContent_wrapper">
                <AntiClsFrame ratio={0.56} width="100%">
                    {!isAmp ? (
                        <div className="videoContent_playerContainer">
                            <VideoJsPlayer
                                className="videoContent_videoJsPlayer"
                                url={url}
                                ads={ads}
                                id={id}
                                isEmbed={isEmbed}
                                autoplay={autoplay}
                                inViewAutoplay={inViewAutoplay}
                                isHead={isHead}
                                thumbnail={thumbnail || '/images/zenahr-logo.png'}
                            />
                        </div>
                    ) : (
                        <AmpVideo prerollAmpTag={prerollAmpTag} src={url} />
                    )}
                </AntiClsFrame>
                {!isEmbed && !isHead && (
                    <div className="videoContent_head">
                        {label && (
                            <HeadingLabel className="videoContent_label">
                                <span {...labelHtmlProps} />
                            </HeadingLabel>
                        )}
                        {subtitle && <div className="videoContent_subtitle" {...subtitleHtmlProps} />}
                        {title && <TitleTag className="videoContent_title" {...titleHtmlProps} />}
                        {lead && <HtmlContent className="videoContent_lead" data={lead} />}
                    </div>
                )}
            </div>
            {!isEmbed && !isHead && (
                <>
                    <Triangle className="videoContent_triangle" />
                    <Graphic
                        className="videoContent_graphic"
                        variant={graphicVariants.ALPHA}
                        fillVariant={graphicFillVariants.ALTERNATIVE}
                    />
                </>
            )}
        </ContentStyled>
    )
}

Content.propTypes = {
    className: PropTypes.string,
    label: PropTypes.string,
    subtitle: PropTypes.string,
    title: PropTypes.string,
    titleTagComponent: PropTypes.elementType,
    lead: PropTypes.string,
    url: PropTypes.string,
    thumbnail: PropTypes.string,
    id: PropTypes.string,
    isEmbed: PropTypes.bool,
    isHead: PropTypes.bool,
    ads: videoAdsPropType,
    autoplay: PropTypes.bool,
    inViewAutoplay: PropTypes.bool
}

Content.defaultProps = {
    className: undefined,
    label: undefined,
    subtitle: undefined,
    title: undefined,
    titleTagComponent: 'h1',
    lead: undefined,
    url: undefined,
    thumbnail: undefined,
    id: undefined,
    isEmbed: false,
    isHead: false,
    ads: [],
    autoplay: false,
    inViewAutoplay: false
}

export default withErrorBoundary(Content, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[VideoContent]: ', error, componentStack)
    }
})
