import { toRem, toRems } from '../../../../helpers/theme'

const Style = ({ theme, variant, imageWidth }) => {
    const config = {
        xs: {
            image: {
                frame: {
                    variant: {
                        alpha: {
                            left: 0,
                            right: 'initial'
                        },
                        beta: {
                            left: 'initial',
                            right: 0
                        }
                    }
                },
                graphic: {
                    variant: {
                        alpha: {
                            top: toRem(40),
                            left: toRem(-25),
                            right: 'initial'
                        },
                        beta: {
                            top: toRem(40),
                            left: 'initial',
                            right: toRem(-25)
                        }
                    }
                }
            }
        },
        md: {
            variant: {
                epsilon: {
                    borderLeft: `${toRem(20)} solid ${theme.colors.article.highlighted.border}`
                }
            },
            image: {
                graphic: {
                    variant: {
                        alpha: {
                            top: toRem(95),
                            left: toRem(-62),
                            right: 'initial'
                        },
                        beta: {
                            top: toRem(125),
                            left: 'initial',
                            right: toRem(-50)
                        }
                    }
                }
            }
        }
    }

    return {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        background: theme.colors.article.highlighted.background,
        [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
            borderLeft: config?.md?.variant[variant]?.borderLeft
        },
        [theme.breakpoints.down('sm')]: {
            padding: toRems([variant === 'alpha' ? 35 : 40, 25, 40])
        },
        '& .sectionImage': {
            position: 'relative',
            ...(variant === 'beta' && {
                order: 1
            }),
            [theme.breakpoints.up('md')]: {
                flexBasis: toRem(imageWidth)
            }
        },
        '& .sectionImage_frame': {
            position: 'absolute',
            top: 0,
            left: config?.xs.image.frame.variant[variant]?.left,
            right: config?.xs.image.frame.variant[variant]?.right,
            height: '100%'
        },
        '& .sectionImage_graphic': {
            position: 'absolute',
            top: config?.xs.image.graphic.variant[variant]?.top,
            left: config?.xs.image.graphic.variant[variant]?.left,
            right: config?.xs.image.graphic.variant[variant]?.right,
            [theme.breakpoints.up('md')]: {
                top: config?.md.image.graphic.variant[variant]?.top,
                left: config?.md.image.graphic.variant[variant]?.left,
                right: config?.md.image.graphic.variant[variant]?.right
            }
        },
        '& .sectionContent': {
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
            minHeight: toRem(150),
            p: {
                marginBottom: toRem(20),
                [theme.breakpoints.up('md')]: {
                    marginBottom: toRem(28)
                }
            },
            ...(variant === 'alpha' && {
                paddingBottom: toRem(30)
            }),
            ...(variant === 'beta' && {
                order: 2
            }),
            [theme.breakpoints.up('md')]: {
                flex: 1,
                padding: toRem(83)
            },
            [theme.breakpoints.down('sm')]: {
                ...(variant === 'beta' && {
                    paddingTop: toRem(25)
                })
            },
            ...(['delta', 'epsilon'].includes(variant) && {
                columnCount: 2,
                columnGap: toRem(100)
            })
        },
        '& .sectionContent_graphic': {
            position: 'absolute',
            top: toRem(-50),
            [theme.breakpoints.up('md')]: {
                top: toRem(-25)
            }
        },
        '& .sectionContent_title': {
            marginBottom: toRem(24),
            [theme.breakpoints.up('md')]: {
                marginBottom: toRem(30)
            }
        },
        ...(['delta', 'epsilon'].includes(variant) && {
            '& .sectionContent_text': {
                [theme.breakpoints.up('md')]: {
                    columnCount: 2,
                    columnGap: toRem(100),
                    marginBottom: toRem(28)
                }
            }
        })
    }
}

export default Style
