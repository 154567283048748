/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import { IDidomiObject } from '@didomi/react'
import useTimeout from '@rooks/use-timeout'
import { useEffect, useRef, useState } from 'react'

export enum ConsentDataSource {
    DIDOMI = 'DIDOMI',
    POST_MESSAGE = 'POST_MESSAGE',
    CUSTOM = 'CUSTOM',
    NONE = 'NONE'
}
type UserConsentState = {
    vendors: string[] // vendor ids in list are enabled
    purposes: string[] // purposes in list are enabled
    userId: string
    created: string
    updated: string
    consentString: string
    additionalConsent: string
    via?: ConsentDataSource
}

const initialState: UserConsentState = {
    vendors: [],
    purposes: [],
    userId: '',
    created: '',
    updated: '',
    consentString: '',
    additionalConsent: ''
}

const parseEnabledFeatureNames = (object: Record<string, { enabled: boolean; [key: string]: any }>): string[] =>
    Object.entries(object)
        .filter(([, value]: [string, any]) => value?.enabled)
        .map(([key]) => key)

const parseConsentData = (
    didomiStatus: any,
    featureParser: (features: Record<string, any>) => string[] = parseEnabledFeatureNames
): UserConsentState => {
    const {
        created,
        updated,
        consent_string: consentString,
        addtl_consent: additionalConsent,
        user_id: userId,
        purposes,
        vendors
    } = didomiStatus
    return {
        vendors: featureParser(vendors),
        purposes: featureParser(purposes),
        userId,
        created,
        updated,
        consentString,
        additionalConsent
    }
}

const parseDidomiConsentStatus = (didomiStatus: any): UserConsentState => parseConsentData(didomiStatus)

const parsePostMessageFeatureNames = (
    object: Record<string, { enabled: (string | number | undefined)[]; disabled: (string | number | undefined)[] }>
): string[] =>
    Object.values(object)
        .map(value => value?.enabled)
        .flat()
        .filter(val => typeof val === 'string')

const parsePostMessageData = (didomiStatus: any): UserConsentState =>
    parseConsentData(didomiStatus, parsePostMessageFeatureNames)

declare global {
    interface Window {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Didomi: IDidomiObject
        didomiOnReady: ((didomi: IDidomiObject) => void)[] | undefined
    }
}

export const useDidomiConsentState = () => {
    const didomiRef = useRef<IDidomiObject | null>(null)
    // @todo: implement timeout for consent data
    const [initiated, setInitiated] = useState(false)
    const sendViaPostMessage = useRef(false)
    const [changed, setChanged] = useState(false)
    const [consent, setConsentState] = useState<UserConsentState>(initialState)
    const [timedOut, setTimedOut] = useState(false)
    const { start: startForceTimeout, stop: stopForceTimeout } = useTimeout(() => setTimedOut(true), 2000)

    const mountedRef = useRef(false)
    useEffect(() => {
        const destroy = () => {
            mountedRef.current = false
            stopForceTimeout()
        }
        if (mountedRef.current) {
            return destroy
        }
        startForceTimeout()
        mountedRef.current = true

        if (window.top !== window.self) {
            // post message
            sendViaPostMessage.current = true
            setInitiated(true)
            return destroy
        }

        if (window.Didomi) {
            setInitiated(true)
            didomiRef.current = window.Didomi
            stopForceTimeout()
            return destroy
        }

        window.didomiOnReady = window.didomiOnReady || []
        window.didomiOnReady.push(didomi => {
            if (mountedRef.current) {
                setInitiated(true)
                stopForceTimeout()
                didomiRef.current = didomi
            }
        })

        return destroy
    }, [])

    useEffect(() => {
        if (!initiated) {
            return () => {}
        }

        if (!sendViaPostMessage.current) {
            const getConsentFromDidomi = () => {
                if (!didomiRef.current) return
                setInitiated(true)
                const userConsentStatus = parseDidomiConsentStatus(didomiRef.current.getCurrentUserStatus())
                // console.log('userConsentStatus', userConsentStatus)
                setConsentState(userConsentStatus)
                setChanged(userConsentStatus.created !== userConsentStatus.updated)
            }
            if (didomiRef.current) {
                getConsentFromDidomi()
                didomiRef.current.on('consent.changed', getConsentFromDidomi)
            }
            return () => {}
        }
        const postMessageListener = (event: MessageEvent) => {
            try {
                const data = typeof event.data === 'string' ? JSON.parse(event.data) : event.data
                if (data && data.__cmpReturn) {
                    // data.__cmpReturn contains the response from the CMP
                    if (data.__cmpReturn.callId === 'get-user-status') {
                        const consentData = parsePostMessageData(data.__cmpReturn.returnValue)

                        // console.log('userConsentStatus via postmessage', consentData)
                        setConsentState(consentData)
                        setChanged(consentData.created !== consentData.updated)
                    }
                }
            } catch (e) {
                // An error happened when decoding the message data
                // Most likely, the message wasn't related to the CMP so do nothing
            }
        }
        window.addEventListener('message', postMessageListener, false)

        window.parent.postMessage(
            {
                __cmpCall: {
                    command: 'getUserStatus',
                    parameter: [],
                    callId: 'get-user-status'
                }
            },
            '*'
        )
        return () => {
            window.removeEventListener('message', postMessageListener)
        }
    }, [initiated])

    return { initiated, changed, consent, timedOut }
}
