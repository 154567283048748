import React from 'react'
import { useAmp } from 'next/amp'
import PropTypes from 'prop-types'

import { AmpIframe } from '../../components/Amp'
import { EmbedSocial } from '../../components/HtmlContent/HtmlContent.style'
import SofascorePostController from './SofascorePost.controller'

export function CommonSofascoreEmbed({ url, height, placeholder }) {
    const isAmp = useAmp()
    const width = 340 / height || 1

    if (!url) {
        return null
    }

    return (
        <EmbedSocial className="u-paddingHeight">
            {isAmp ? (
                <AmpIframe width={width} height={1} src={url} placeholder={placeholder} />
            ) : (
                <SofascorePostController url={url} height={height} />
            )}
        </EmbedSocial>
    )
}

CommonSofascoreEmbed.propTypes = {
    url: PropTypes.string,
    height: PropTypes.number,
    placeholder: PropTypes.string
}

CommonSofascoreEmbed.defaultProps = {
    url: undefined,
    height: 200,
    placeholder: '/images/placeholder/net_logos.png'
}
