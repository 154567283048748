import React, { useMemo } from 'react'
import { useAmp } from 'next/amp'
import dynamic from 'next/dynamic'
import PropTypes from 'prop-types'

import { AmpTikTok } from '../../components/Amp'
import { EmbedSocial } from '../../components/HtmlContent/HtmlContent.style'
import { RenderInView } from '../../components/RenderInView'

const TikTokItemController = dynamic(
    // eslint-disable-next-line import/extensions
    () => import('./TikTokItem.controller'),
    { ssr: false }
)
const tikTokRegex =
    /(http(s){0,1}:\/{2}){0,1}(m|www)\.tiktok\.com\/(?<user>@[^/]+){0,1}(|\w+|[?/]){1,6}(\/|=)(?<id>\d{19})/i
export function CommonTikTokEmbed({ url }) {
    const isAmp = useAmp()
    const {
        id // user
    } = useMemo(() => {
        const { groups = {} } = tikTokRegex.exec(url) || {}
        return groups
    }, [url])

    if (!url || (isAmp && !id)) {
        return null
    }
    // @TODO: find a way to embed tiktok on amp
    if (isAmp) {
        return <AmpTikTok id={id} />
    }

    return (
        <EmbedSocial>
            <RenderInView>
                <TikTokItemController url={url} />
            </RenderInView>
        </EmbedSocial>
    )
}
CommonTikTokEmbed.propTypes = {
    url: PropTypes.string
}
CommonTikTokEmbed.defaultProps = {
    url: undefined
}
