/* eslint-disable @typescript-eslint/no-unused-vars */
import { toRem, toRems } from '../../helpers/theme'

const largeTitleMarginTopXs = 40 // h1, h2, h3, h4

const smallTitleMarginTopXs = 20 // h5, h6

const style = ({ theme, endDecoration }) => ({
    width: '100%',
    color: theme.colors.text.default,
    h1: {
        margin: toRems([0, 0, 30, 0])
    },
    '& > * + h1': {
        marginTop: toRem(largeTitleMarginTopXs)
    },
    h2: {
        margin: toRems([0, 0, 30, 0])
    },
    '& > * + h2': {
        marginTop: toRem(largeTitleMarginTopXs)
    },
    h3: {
        margin: toRems([0, 0, 20, 0])
    },
    '& > * + h3': {
        marginTop: toRem(largeTitleMarginTopXs)
    },
    h4: {
        margin: toRems([0, 0, 20, 0])
    },
    '& > * + h4': {
        marginTop: toRem(largeTitleMarginTopXs - 8)
    },
    '& h5, h6': {
        margin: toRems([0, 0, 4, 0])
    },
    '& > * + h5, > * + h6': {
        marginTop: toRem(smallTitleMarginTopXs)
    },
    '& > .marginCollapse +': {
        'h1, h2, h3, h4': {
            marginTop: toRem(largeTitleMarginTopXs - theme.global.htmlContent.xs.blockVerticalMargin),

            [theme.breakpoints.up('md')]: {
                marginTop: toRem(largeTitleMarginTopXs - theme.global.htmlContent.md.blockVerticalMargin)
            }
        },
        'h5, h6': {
            marginTop: toRem(smallTitleMarginTopXs - theme.global.htmlContent.xs.blockVerticalMargin),

            [theme.breakpoints.up('md')]: {
                marginTop: toRem(smallTitleMarginTopXs - theme.global.htmlContent.md.blockVerticalMargin)
            }
        }
    },
    img: {
        // Note(@mario.basic, external-image): I don't know why this
        // style is applied before the style in Image.style.js.
        // I have commented this out, but if it starts making problems
        // maybe try solving the root issue.
        // width: '100%',
        // height: 'auto',
        maxWidth: '100%'
    },
    '& .Image-noPlaceholder.Image-isLoading': {
        display: 'none'
    },
    p: {
        marginBottom: toRem(theme.global.htmlContent.xs.blockVerticalMargin),
        position: 'relative',
        ...(endDecoration && {
            '&:last-of-type': {
                '&::after': {
                    content: '""',
                    display: 'inline-block',
                    verticalAlign: 'sub',
                    marginLeft: toRem(5),
                    height: toRem(25),
                    width: toRem(25),
                    background: 'url(/svg/icons/rose.svg)',
                    backgroundSize: 'contain'
                }
            }
        }),
        [theme.breakpoints.up('md')]: {
            marginBottom: toRem(theme.global.htmlContent.md.blockVerticalMargin)
        }
    },

    a: {
        color: theme.colors.link.primary.text.default,
        borderBottom: `1px solid ${theme.colors.link.primary.border.default}`,
        '&:focus': {
            color: theme.colors.link.primary.text.focus,
            borderBottom: 'none',
            outline: `1px dashed ${theme.colors.link.primary.border.focus}`
        },
        '&:hover': {
            color: theme.colors.link.primary.text.hover,
            borderBottom: `1px solid ${theme.colors.link.primary.border.hover}`
        },
        '&:active': {
            color: theme.colors.link.primary.text.active,
            borderBottom: `1px solid ${theme.colors.link.primary.border.active}`
        }
    },
    ol: {
        margin: toRems([theme.global.htmlContent.xs.blockVerticalMargin * 1.5, 0]),
        counterReset: 'ol-list-counter',
        [theme.breakpoints.up('md')]: {
            margin: toRems([theme.global.htmlContent.md.blockVerticalMargin * 1.8, 0])
        },
        '> li': {
            counterIncrement: 'ol-list-counter',
            margin: toRems([0, 0, 18, 0]),
            padding: toRems([0, 0, 0, 30]),
            [theme.breakpoints.up('md')]: {
                margin: toRems([0, 0, 26, 0])
            },
            '&::before': {
                content: 'counter(ol-list-counter) "."',
                display: 'block',
                position: 'relative',
                maxWidth: 0,
                maxHeight: 0,
                left: toRem(-30),
                color: theme.colors.list.decimal.default
            },
            ul: {
                li: {
                    '&::before': {
                        content: '""'
                    }
                }
            },
            'ul, ol': {
                flexBasis: '100%',
                counterReset: 'ol-list-counter'
            },
            '&:last-of-type': {
                marginBottom: 0
            }
        }
    },
    ul: {
        margin: toRems([theme.global.htmlContent.xs.blockVerticalMargin * 1.5, 0]),
        [theme.breakpoints.up('md')]: {
            margin: toRems([theme.global.htmlContent.md.blockVerticalMargin * 1.8, 0])
        },
        '> li': {
            position: 'relative',
            margin: toRems([0, 0, 18, 0]),
            paddingLeft: toRem(30),
            [theme.breakpoints.up('md')]: {
                margin: toRems([0, 0, 26, 0]),
                paddingLeft: toRem(50)
            },
            '&::before': {
                content: '""',
                position: 'absolute',
                top: toRem(10),
                left: 0,
                marginRight: toRem(15),
                width: toRem(20),
                height: 2,
                background: theme.colors.list.dash.default,
                [theme.breakpoints.up('md')]: {
                    top: toRem(14),
                    width: toRem(35)
                }
            },
            'ul, ol': {
                flexBasis: '100%'
            },
            '&:last-of-type': {
                marginBottom: 0
            }
        }
    },
    table: {
        width: '100%',
        fontSize: toRem(18),
        padding: toRems([0, 30]),
        marginBottom: toRem(50),
        [theme.breakpoints.up('md')]: {
            border: `1px solid ${theme.colors.border.light}`
        },
        borderCollapse: 'collapse',
        tr: {
            borderBottom: `1px solid ${theme.colors.border.light}`,
            '&:first-of-type': {
                borderBottom: `3px solid ${theme.colors.border.light}`
            },
            '&:last-of-type': {
                borderBottom: 'none'
            }
        }
    },
    th: {
        padding: toRem(10),
        textAlign: 'center',
        color: theme.colors.text.alternative
    },
    td: {
        padding: toRem(10),
        textAlign: 'center',
        minWidth: '100px',

        '&:first-of-type': {
            minWidth: '100px',
            maxWidth: '200px',
            textWrap: 'wrap',
            wordWrap: 'break-word'
        },

        '&:last-of-type': {
            minWidth: '300px'
        }
    },
    tf: {
        padding: toRem(10),
        textAlign: 'center'
    }
})

export default style
