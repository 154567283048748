import { toRem, toRems } from '../../../../helpers/theme'

const style = ({ theme, variant, isLessThanHalf }) => {
    const config = {
        sm: {
            pollGraphics: {
                left: 0
            },
            width: `calc(100% + ${toRem(50)})`
        },
        md: {
            pollGraphics: {
                left: toRem(-15)
            },
            variant: {
                epsilon: { padding: toRems([38, 0, 55, 50]) }
            },
            header: {
                variant: {
                    epsilon: { paddingRight: toRem(40) }
                }
            }
        }
    }
    return {
        margin: toRems([0, -25]),
        display: 'flex',
        position: 'relative',
        width: config?.sm?.width,
        [theme.breakpoints.up('md')]: {
            width: '100%',
            margin: 0
        },
        padding: config?.md?.variant[variant]?.padding || toRems([38, 40, 55, 50]),
        background: theme.colors.poll.background,
        '& .pollGraphics': {
            position: 'absolute',
            top: '5%',
            height: '90%',
            left: config?.sm?.pollGraphics?.left,
            [theme.breakpoints.up('md')]: {
                left: config?.md?.pollGraphics?.left
            }
        },
        '& .pollTitle': {
            fontSize: toRem(20),
            marginBottom: toRem(20)
        },
        '& .pollForm': {
            width: '100%',
            '& .itemsWrapper': {
                display: 'flex',
                justifyContent: 'space-between',
                flexWrap: 'wrap',
                scrollbarWidth: 0,
                '&::-webkit-scrollbar': {
                    width: 0
                }
            },
            '&_header': {
                display: 'flex',
                justifyContent: 'space-between',
                paddingRight: config?.md?.header?.variant[variant]?.paddingRight
            },
            '& .pollSlider': {
                height: '30%',
                ...(isLessThanHalf && { width: '30%' }),
                '&_button': {
                    width: toRem(30),
                    minHeight: toRem(30),
                    height: toRem(30),
                    '& svg': {
                        width: toRem(16),
                        height: toRem(14)
                    }
                }
            },
            '& .pollSubmit': {
                '& svg': {
                    width: toRem(16),
                    height: toRem(16)
                }
            },
            '& .pollTotal': {
                fontSize: toRem(16),
                lineHeight: 1.4,
                marginTop: toRem(13),
                '&_votes': {
                    fontWeight: theme.typography.fontWeight.bold
                }
            },
            '& .swiper': {
                marginBottom: toRem(20)
            },
            '& .swiper-slide': {
                height: '100%',
                width: '45%',
                ...(!isLessThanHalf && {
                    [theme.breakpoints.up('md')]: {
                        width: '30%'
                    }
                })
            }
        }
    }
}

export default style
