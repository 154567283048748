import { toRem, toRems } from '../../../../../helpers/theme'

/* eslint-disable quote-props, no-dupe-keys, max-len */
const style = ({ theme }) => ({
    '& .sectionSignature_author': {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        fontSize: toRem(14),
        lineHeight: 1.4,
        fontWeight: theme.typography.fontWeight.semiBold,
        '&::before': {
            content: '""',
            flexShrink: 0,
            margin: toRems([0, 10, 0, 0]),
            width: toRem(30),
            height: 1,
            background: theme.colors.text.default
        },
        [theme.breakpoints.up('md')]: {
            fontSize: toRem(16)
        }
    },
    '& .sectionSignature_authorDesc': {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        margin: toRems([2, 0, 0, 40]),
        color: theme.colors.text.alternative,
        fontSize: toRem(14),
        lineHeight: 1.4
    }
})

export default style
