/**
 * It extracts the Youtube video ID from given URL string.
 *
 * @test https://regex101.com/r/4BGf0I/1
 * @source https://gist.github.com/rodrigoborgesdeoliveira/987683cfbfcc8d800192da1e73adc486?permalink_comment_id=3263133#gistcomment-3263133
 * @param url Youtube video URL
 * @returns It returns the video ID or false.
 */
const extractYoutubeVideoId = (url: string): string | false => {
    const regExp = /(?:\/|%3D|v=|vi=)([0-9A-z-_]{11})(?:[%#?&]|$)/i

    const match = regExp.exec(url)

    return match?.[1] || false
}

export default extractYoutubeVideoId
