import PropTypes from 'prop-types'

function AmpTwitter({ width, height, id }) {
    return (
        id && <amp-twitter data-tweetid={id} layout="responsive" width={width.toString()} height={height.toString()} />
    )
}

AmpTwitter.propTypes = {
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    id: PropTypes.string
}

AmpTwitter.defaultProps = {
    width: 375,
    height: 472,
    id: undefined
}
export default AmpTwitter
