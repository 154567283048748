import { toRem } from '../../../../../helpers/theme'

/* eslint-disable quote-props, no-dupe-keys */
const style = ({ theme }) => ({
    '& span': {
        paddingRight: toRem(10),
        backgroundColor: theme.colors.article.blockquote.background
    }
})

export default style
