import styled from '@emotion/styled'
import { useAmp } from 'next/amp'
import PropTypes from 'prop-types'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'
import { useHtmlContent } from '@hmn/rtl-web-core/hooks'

import { elements } from './HtmlContent.elements'
import styles from './HtmlContent.style'

const HtmlContentStyled = styled.article(props => ({ ...styles(props) }))
function HtmlContent({ className, endDecoration, data, page, children, insertElements }) {
    const isAmp = useAmp()
    const htmlCont = useHtmlContent({
        docbook: data,
        customElements: elements,
        currentPage: page,
        insertElements,
        isAmp
    })
    const { Content, currentPage, totalPages, blockList } = htmlCont
    return (
        <HtmlContentStyled endDecoration={endDecoration} className={className}>
            {children ? children({ Content, currentPage, totalPages, blockList }) : <Content />}
        </HtmlContentStyled>
    )
}

HtmlContent.propTypes = {
    data: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.shape({}))]),
    page: PropTypes.number,
    insertElements: PropTypes.arrayOf(
        PropTypes.shape({
            every: PropTypes.number,
            afterBlocks: PropTypes.arrayOf(PropTypes.string), // array of block names after which to insert element
            offset: PropTypes.number,
            count: PropTypes.number,
            // eslint-disable-next-line react/forbid-prop-types
            elements: PropTypes.arrayOf(PropTypes.object)
        })
    ),
    endDecoration: PropTypes.bool,
    className: PropTypes.string
}

HtmlContent.defaultProps = {
    data: undefined,
    page: undefined,
    insertElements: undefined,
    endDecoration: false,
    className: undefined
}

export default withErrorBoundary(HtmlContent, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[HtmlContent]: ', error, componentStack)
    }
})
