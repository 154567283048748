import PropTypes from 'prop-types'

function AmpVideo({ prerollAmpTag, src, poster }) {
    const AmpVideoComponent = prerollAmpTag ? 'amp-ima-video' : 'amp-video'
    return (
        <AmpVideoComponent
            width="480"
            height="270"
            data-poster={poster || '/images/placeholder/net_logos.png'}
            layout="responsive"
            {...((prerollAmpTag && { 'data-tag': prerollAmpTag, layout: 'responsive' }) || {
                autoplay: '',
                controls: ''
            })}>
            <source type="video/mp4" src={src} />
        </AmpVideoComponent>
    )
}

AmpVideo.propTypes = {
    src: PropTypes.string,
    prerollAmpTag: PropTypes.string,
    poster: PropTypes.string
}

AmpVideo.defaultProps = {
    src: undefined,
    prerollAmpTag: undefined,
    poster: undefined
}
export default AmpVideo
