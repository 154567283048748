import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'
import { useInnerHtml } from '@hmn/rtl-web-core/hooks'

import { Button, buttonVariants } from '../../../Button'
import { Graphic, graphicVariants } from '../../../Decoration/components'
import { ArrowRightIcon, CounselingIcon, Icon } from '../../../Icon'
import styles from './CtaBlock.style'

const CtaBlockStyled = styled.div(props => ({ ...styles(props) }))

function CtaBlock({
    text,
    title,
    titleTagComponent: TitleTag,
    buttonLabel,
    buttonHref,
    buttonHrefAs,
    className,
    icon,
    ...rest
}) {
    const titleHtmlProps = useInnerHtml(title)
    const textHtmlProps = useInnerHtml(text)

    if (!text) {
        return null
    }

    return (
        <CtaBlockStyled className={className} {...rest}>
            <div className="ctaBlock">
                <div className="ctaBlock_content">
                    <div className="ctaBlock_iconWrap">
                        <Icon className="ctaBlock_icon" icon={icon} height={70} viewBox="0 0 70 70" />
                    </div>
                    {title && <TitleTag className="ctaBlock_title" {...titleHtmlProps} />}
                    <div className="ctaBlock_text" {...textHtmlProps} />
                </div>
                {buttonLabel && buttonHref && (
                    <Button
                        title={buttonLabel}
                        className="ctaBlock_button"
                        href={buttonHref}
                        hrefAs={buttonHrefAs}
                        width="25%"
                        widthMobile="100%"
                        variant={buttonVariants.BETA}
                        iconComponent={<Icon icon={ArrowRightIcon} />}>
                        {buttonLabel}
                    </Button>
                )}
                <Graphic className="ctaBlock_graphic" heightMd={28} heightXs={20} variant={graphicVariants.GAMMA} />
            </div>
        </CtaBlockStyled>
    )
}

CtaBlock.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    titleTagComponent: PropTypes.elementType,
    text: PropTypes.string,
    buttonLabel: PropTypes.string,
    buttonHref: PropTypes.string,
    buttonHrefAs: PropTypes.string,
    icon: PropTypes.elementType
}

CtaBlock.defaultProps = {
    className: undefined,
    title: undefined,
    titleTagComponent: 'h1',
    text: undefined,
    buttonLabel: undefined,
    buttonHref: undefined,
    buttonHrefAs: undefined,
    icon: CounselingIcon
}

export default withErrorBoundary(CtaBlock, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[ArticleCtaBlock]: ', error, componentStack)
    }
})
