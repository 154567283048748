/* eslint-disable camelcase, max-len  */
import React, { useRef } from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'
import { useVideoAdList } from '@hmn/rtl-web-core/context/ads/manifest.context'
import { useInnerHtml, useVideoThumbnail } from '@hmn/rtl-web-core/hooks'

import { Graphic, graphicVariants, Triangle } from '../../../Decoration/components'
import { HeadingLabel } from '../../../Heading/components/Label'
import { HtmlContent } from '../../../HtmlContent'
import { VideoContent } from '../../../Video/components'
import styles from './VideoHead.style'

const VideoHeadStyled = styled.div(props => ({ ...styles(props) }))

function VideoHead({
    className,
    label,
    labelLink,
    labelTitle,
    subtitle,
    title,
    titleTagComponent: TitleTag,
    lead,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    alt,
    halfGreyBackground,
    rtlUrl: videoUrl,
    nativeVideo,
    ...rest
}) {
    const heroRef = useRef()
    const labelHtmlProps = useInnerHtml(label)
    const subtitleHtmlProps = useInnerHtml(subtitle)
    const titleHtmlProps = useInnerHtml(title)
    const thumbnail = useVideoThumbnail({ videoElastic: nativeVideo, width: 800, height: 600 })

    const videoAds = useVideoAdList()
    const renderIFrame = (() => {
        if (videoUrl && !nativeVideo) {
            return (
                <iframe
                    style={{ border: '0' }}
                    className="articleHero_videoIframe"
                    title={videoUrl}
                    src={videoUrl}
                    allow="autoplay"
                />
            )
        }

        return null
    })()

    return (
        <VideoHeadStyled className={className} halfGreyBackground={halfGreyBackground} {...rest}>
            <div className="articleHead">
                {label && (
                    <HeadingLabel href={labelLink} hrefAs={labelLink} title={labelTitle} className="articleHead_label">
                        <span {...labelHtmlProps} />
                    </HeadingLabel>
                )}
                {subtitle && <div className="articleHead_subtitle" {...subtitleHtmlProps} />}
                {title && (
                    <div className="articleHead_titleWrap">
                        <TitleTag className="articleHead_title" {...titleHtmlProps} />
                    </div>
                )}
                {lead && (
                    <div className="articleHead_lead">
                        <HtmlContent data={lead} />
                    </div>
                )}
            </div>
            {(videoUrl || nativeVideo) && (
                <div className="articleHero_wrapper">
                    <div className="articleHero" ref={heroRef}>
                        {renderIFrame}
                        {nativeVideo && (
                            <VideoContent
                                ads={videoAds}
                                alt={nativeVideo?.image || nativeVideo?.thumbnail}
                                className="articleHero_video"
                                id={nativeVideo.id}
                                inViewAutoplay
                                isHead
                                sticky
                                isMainContent
                                lead={nativeVideo?.description}
                                subtitle={nativeVideo?.extended_attributes?.subtitle}
                                thumbnail={thumbnail}
                                title={nativeVideo?.name}
                                url={
                                    nativeVideo?.extended_attributes?.stream_url ||
                                    nativeVideo?.original_url ||
                                    nativeVideo?.custom_manifest_url
                                }
                            />
                        )}
                        <Triangle className="imageDecoration_triangle" />
                        <Graphic className="imageDecoration_graphic" variant={graphicVariants.ALPHA} />
                    </div>
                </div>
            )}
        </VideoHeadStyled>
    )
}

// 'https://www.rtl.hr/vijesti-hr/embed/video/vijesti/444096/amerikanci-optuzili-hakersku-skupinu-za-zaustavljanje-naftnog-pogona-nafta-vise-ne-tece-s-jednog-kraja-sad-a-na-drugi/'

VideoHead.propTypes = {
    className: PropTypes.string,
    label: PropTypes.string,
    labelLink: PropTypes.string,
    labelTitle: PropTypes.string,
    subtitle: PropTypes.string,
    title: PropTypes.string,
    titleTagComponent: PropTypes.elementType,
    lead: PropTypes.string,
    alt: PropTypes.string,
    halfGreyBackground: PropTypes.bool,
    rtlUrl: PropTypes.string,
    nativeVideo: PropTypes.shape({
        extended_attributes: PropTypes.shape({
            subtitle: PropTypes.string,
            stream_url: PropTypes.string
        }),
        name: PropTypes.string,
        original_url: PropTypes.string,
        custom_manifest_url: PropTypes.string,
        description: PropTypes.string,
        image: PropTypes.string,
        thumbnail: PropTypes.string,
        id: PropTypes.string.isRequired
    }).isRequired
}

VideoHead.defaultProps = {
    className: undefined,
    label: undefined,
    labelLink: undefined,
    labelTitle: undefined,
    subtitle: undefined,
    title: undefined,
    titleTagComponent: 'h1',
    lead: undefined,
    alt: undefined,
    halfGreyBackground: false,
    rtlUrl: undefined
}

export default withErrorBoundary(VideoHead, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[ArticleVideoHead]: ', error, componentStack)
    }
})
