import { useMemo } from 'react'
import { NextSeo } from 'next-seo'
import PropTypes from 'prop-types'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'
import stripHtml from '@hmn/rtl-web-core/helpers/js/stripHtmlTags'
import truncate from '@hmn/rtl-web-core/helpers/js/truncateString'
import { objectToArrayOrAny } from '@hmn/rtl-web-core/helpers/object-to-array-or-any'
import safelyJoinSlugs from '@hmn/rtl-web-core/helpers/safelyJoinSlugs'
import parseSeoDescription from '@hmn/rtl-web-core/helpers/seo/js/parseSeoDescription'
import { processOpenGraphImages } from '@hmn/rtl-web-core/helpers/seo/js/processOpenGraphImages'
import { useBreakpoints, useSeoImages } from '@hmn/rtl-web-core/hooks'

import { dateToSeo, organizationStructuredData, sitemapToBreadcrumbs, StructuredData, tagsToSeo } from '../../../Seo'

const generateArticleStructuredData = (
    item,
    imageUrl = `${process.env.NEXT_PUBLIC_APP_ROOT_ZENA || ''}/images/share/default.jpg`,
    bodyMaxWords = 100
) => {
    const title = truncate(stripHtml(item?.title))
    const description = parseSeoDescription(item?.description || item?.lead)

    const itemTaxonsSitemapPath = objectToArrayOrAny(item?.taxons?.sitemap?.[0]?.path)
    const sections = itemTaxonsSitemapPath?.map(p => p?.title).filter(Boolean)

    const author = 'Žena.hr' || item?.author || item?.source || item?.extended_attributes?.author || 'Žena.hr'
    const publishDate = dateToSeo(item?.published_at || item?.updated_at)
    const updateDate = dateToSeo(item?.updated_at)
    const content = stripHtml(item?.body) || description
    const itemHref =
        item?.href || item?.sitemap?.href || (item?.path && `/${safelyJoinSlugs(item?.path?.map(p => p.slug))}`) || ''

    return {
        name: title,
        image: imageUrl,
        '@type': 'Article',
        headline: truncate(title, 110, '...'),
        description,
        articleSection: sections,
        articleBody: `${content?.split(' ').slice(0, bodyMaxWords).join(' ')}${
            content?.split(' ').length > bodyMaxWords ? '...' : ''
        }`,
        inLanguage: 'Croatian',
        thumbnailUrl: imageUrl,
        datePublished: publishDate,
        dateModified: updateDate,
        author,
        publisher: organizationStructuredData,
        mainEntityOfPage: `${process.env.NEXT_PUBLIC_APP_ROOT_ZENA}${itemHref}`
    }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function Seo({ item, images, hasAmp, shareUrl, structuredDataOverwrite, pagedItem, pagedImages, page }) {
    // @TODO: merge images once they're available directly through item
    const [deviceType] = useBreakpoints(['xs', 'sm', 'md'], ['mobile', 'mobile', 'desktop'], null)
    const { imageMeta } = useSeoImages([...(images || []), ...((!!page && pagedImages) || [])], {
        url: `${process.env.NEXT_PUBLIC_APP_ROOT_ZENA || ''}/images/share/default.jpg`,
        width: 1200,
        height: 630
    })

    const { seo, structuredData } = useMemo(() => {
        if (!item?.id) {
            return {}
        }
        const {
            google_title: googleTitle,
            mobile_title: mobileTitle,
            social_title: socialTitleInput
        } = item?.extended_attributes || {}

        const title = truncate(stripHtml(item?.title || (!!page && pagedItem?.title)))
        const deviceTitle = (deviceType === 'mobile' && mobileTitle) || title
        const description = parseSeoDescription(item?.description || item?.lead)
        // @TODO: load author/source properly, for now override with zena.hr
        const author = 'Žena.hr' || item?.author || item?.source || item?.extended_attributes?.author || 'Žena.hr'

        const publishDate = dateToSeo(item?.published_start || item?.updated_at)
        const updateDate = dateToSeo(item?.updated_at)

        const itemHref =
            item?.sitemap?.href ||
            (item?.path && `/${safelyJoinSlugs(item?.path?.map(p => p.slug))}`) ||
            item?.href ||
            ''
        const url = shareUrl || `${process.env.NEXT_PUBLIC_APP_ROOT_ZENA}${itemHref}`

        const tags = tagsToSeo(item?.tags || [])

        const itemTaxonsSitemapPath = objectToArrayOrAny(item?.taxons?.sitemap?.[0]?.path)
        const breadcrumbs = sitemapToBreadcrumbs(itemTaxonsSitemapPath)

        return {
            seo: {
                title: `${googleTitle || deviceTitle}${page ? ` | Stranica ${page}` : ''}`,
                description,
                canonical: url,
                openGraph: {
                    url,
                    title: `${socialTitleInput || deviceTitle}${page ? ` | Stranica ${page}` : ''}`,
                    description,
                    type: 'article',
                    images: processOpenGraphImages(imageMeta, title),
                    updateTime: updateDate,
                    article: {
                        publishedTime: publishDate,
                        modifiedTime: updateDate,
                        authors: [author],
                        tags
                    }
                },
                twitter: {
                    creator: author
                },
                robotsProps: {
                    maxImagePreview: 'large',
                    maxVideoPreview: 10
                }
            },
            structuredData: [
                {
                    '@context': 'https://schema.org',
                    ...organizationStructuredData
                },
                {
                    '@context': 'https://schema.org',
                    '@type': 'WebPage',
                    name: `${googleTitle || deviceTitle}${page ? ` | Stranica ${page}` : ''}`,

                    publisher: organizationStructuredData
                },
                breadcrumbs && {
                    '@context': 'https://schema.org',
                    '@type': 'BreadcrumbList',
                    name: title,
                    description,
                    itemListElement: breadcrumbs
                },
                {
                    '@context': 'https://schema.org',
                    ...generateArticleStructuredData(
                        { ...(item || {}), ...((page && pagedItem) || {}) },
                        imageMeta?.[0]?.url,
                        1000
                    ),
                    name: `${googleTitle || deviceTitle}${page ? ` | Stranica ${page}` : ''}`,
                    headline: `${googleTitle || deviceTitle}${page ? ` | Stranica ${page}` : ''}`,

                    dateCreated: publishDate,
                    ...(structuredDataOverwrite || {})
                }
            ].filter(Boolean),
            href: `${process.env.NEXT_PUBLIC_APP_ROOT_ZENA}${itemHref}`
        }
    }, [item, pagedItem, deviceType, shareUrl, imageMeta, page, structuredDataOverwrite])
    // console.log(item, structuredData)
    return (
        <>
            {seo && seo.openGraph?.images?.[0] && <NextSeo {...seo} />}
            {structuredData && <StructuredData data={structuredData} />}
        </>
    )
}

Seo.propTypes = {
    item: PropTypes.oneOfType([() => null, PropTypes.object]),
    pagedItem: PropTypes.oneOfType([() => null, PropTypes.object]),
    structuredDataOverwrite: PropTypes.oneOfType([() => null, PropTypes.object]),
    images: PropTypes.oneOfType([
        () => null,
        PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]))
    ]),
    pagedImages: PropTypes.oneOfType([
        () => null,
        PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]))
    ]),
    page: PropTypes.number,
    hasAmp: PropTypes.bool,
    shareUrl: PropTypes.string
}

Seo.defaultProps = {
    page: 0,
    item: undefined,
    pagedItem: undefined,
    structuredDataOverwrite: undefined,
    images: [],
    pagedImages: [],
    hasAmp: false,
    shareUrl: undefined
}

export { generateArticleStructuredData }
export default withErrorBoundary(Seo, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[ArticleSeo]: ', error, componentStack)
    }
})
