import dynamic from 'next/dynamic'
import PropTypes from 'prop-types'

import { AntiClsFrame } from '../../components/AntiClsFrame'
import { RenderInView } from '../../components/RenderInView'
import { defaultProps, instanceDefaultProps, instancePropTypes, propTypes } from './FacebookItem.proptypes'

const DynamicFacebookItem = dynamic(() => import('./FacebookItem.controller').then(m => m.default), {
    ssr: false
})

function LazyFacebookItem({ viewPorts, ...rest }) {
    return (
        <AntiClsFrame minHeightMd={200} minHeightSm={200}>
            <RenderInView viewPorts={viewPorts} Component={DynamicFacebookItem} {...rest} />
        </AntiClsFrame>
    )
}
export const lazyInstancePropTypes = {
    ...instancePropTypes,
    viewPorts: PropTypes.number
}

export const lazyInstanceDefaultProps = {
    ...instanceDefaultProps,
    viewPorts: 0.3
}

LazyFacebookItem.propTypes = {
    ...propTypes,
    ...lazyInstancePropTypes
}

LazyFacebookItem.defaultProps = { ...defaultProps, ...lazyInstanceDefaultProps }
export default LazyFacebookItem
