/* eslint-disable react/prop-types */

function AmpAnalytics({ upScoreData, gemiusId, dotmetricsId, gtmId }) {
    return (
        <>
            {dotmetricsId && (
                <amp-analytics config={`https://script.dotmetrics.net/AmpConfig.json?id=${dotmetricsId}`} />
            )}
            {gtmId && (
                <amp-analytics
                    config={`https://www.googletagmanager.com/amp.json?id=${gtmId}&gtm.url=SOURCE_URL`}
                    data-credentials="include"
                />
            )}
            {upScoreData && (
                <amp-analytics type="upscore" id="upscore">
                    <script
                        type="application/json"
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{ __html: JSON.stringify({ vars: upScoreData }, null, 2) }}
                    />
                </amp-analytics>
            )}
            {gemiusId && (
                <amp-analytics config="https://hr.hit.gemius.pl/amp.config.json">
                    <script
                        type="application/json"
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                            __html: JSON.stringify(
                                {
                                    vars: {
                                        prefix: 'hr',
                                        identifier: gemiusId
                                    }
                                },
                                null,
                                2
                            )
                        }}
                    />
                </amp-analytics>
            )}
        </>
    )
}
export default AmpAnalytics
