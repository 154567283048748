import { toRem, toRems } from '../../../../helpers/theme'

/* eslint-disable quote-props, no-dupe-keys, max-len */
const style = ({ theme, halfGreyBackground }) => {
    const config = {
        sm: {
            titleWrap: {
                justifyContent: 'space-between'
            }
        },
        md: {
            titleWrap: {
                justifyContent: 'center'
            }
        }
    }
    return {
        position: 'relative',
        padding: toRems([20, 0, 0, 0]),
        [theme.breakpoints.up('md')]: {
            padding: toRems([50, 0]),
            ...(halfGreyBackground && {
                '&::before': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    background: `linear-gradient(90deg, ${theme.colors.background.body.default} 50%, ${theme.colors.background.body.light} 50%)`,
                    width: '100%',
                    height: '65%'
                }
            })
        },
        [theme.breakpoints.down('md')]: {
            marginBottom: toRem(30)
        },
        '& .articleHead': {
            // @TODO: connect to layout dimensions for head
            maxWidth: toRem(1215),
            padding: toRems([0, 25]),
            position: 'relative',
            zIndex: theme.zIndex.over,
            // [theme.breakpoints.up('md')]: {
            margin: '0 auto',
            textAlign: 'center'
            // }
        },
        '& .articleHead_label': {
            // [theme.breakpoints.up('md')]: {
            display: 'flex',
            justifyContent: 'center',
            marginBottom: toRem(50),
            [theme.breakpoints.down('sm')]: {
                marginBottom: toRem(12)
            }
            // }
        },
        '& .articleHead_subtitle': {
            color: theme.colors.subtitle.default,
            fontSize: toRem(16),
            lineHeight: 1.4,
            [theme.breakpoints.up('md')]: {
                fontSize: toRem(20)
            }
        },
        '& .articleHead_titleWrap': {
            display: 'flex',
            justifyContent: config?.sm?.titleWrap?.justifyContent,
            alignItems: 'center'
        },
        '& .articleHead_title': {
            margin: toRems([10, 'auto', 16]), // revise this after client's response
            color: theme.colors.text.default,
            [theme.breakpoints.up('md')]: {
                margin: toRems([15, 'auto'])
            }
        },
        '& .articleHead_overall': {
            marginLeft: toRem(20)
        },
        '& .articleHead_lead': {
            marginBottom: toRem(25),
            fontSize: toRem(20),
            fontWeight: theme.typography.fontWeight.extraLight,
            lineHeight: 1.6,
            p: {
                fontSize: 'inherit',
                lineHeight: 'inherit',
                fontWeight: 'inherit',
                color: theme.colors.text.default
            },
            [theme.breakpoints.up('md')]: {
                marginBottom: toRem(70),
                fontSize: toRem(32)
            }
        },
        '& .articleHero_wrapper': {
            // @TODO: connect to layout dimensions for head
            maxWidth: toRem(1215),
            margin: 'auto',
            // height: toRem(300),
            [theme.breakpoints.up('md')]: {
                // height: toRem(600),
                padding: toRems([0, 25])
            }
        },
        '& .articleHero': {
            position: 'relative',
            marginBottom: toRem(40),
            height: '100%',
            [theme.breakpoints.up('md')]: {
                marginBottom: toRem(82)
            },
            '&_video': {
                padding: 0
            },
            '&_videoIframe': {
                height: '100%',
                width: '100%'
            }
        },
        '& .imageDecoration_triangle': {
            position: 'absolute',
            right: 0,
            bottom: 0
        },
        '& .imageDecoration_graphic': {
            position: 'absolute',
            right: toRem(15),
            bottom: toRem(-20),
            [theme.breakpoints.up('md')]: {
                right: toRem(-25),
                bottom: toRem(-25)
            }
        }
    }
}

export default style
