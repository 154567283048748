import videojs from 'video.js'
import adsPlugin from 'videojs-contrib-ads/dist/videojs.ads'

import 'videojs-ima'
import type rtlVideoJs from './RtlVideoJs'
import type { RtlVideoJsCallback } from './RtlVideoJs'
// hack to register ads plugin, check why it doesn't autoregister via normal import
// import 'videojs-contrib-ads'
if (!videojs.getPlugin('ads')) {
    videojs.registerPlugin('ads', adsPlugin)
}
//  else {
//     console.log('ads plugin already registered')
// }

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const adsManagerLoadedCallbackfactory = (player: rtlVideoJs, events?: { [key: string]: RtlVideoJsCallback }) => () => {
    // @ts-expect-error: ima plugin is not typed
    const ima = player.player.ima
    if (!events) {
        return
    }
    const keys = Object.keys(events)
    // console.log("gtmVideo",keys, events)
    const eventNames: any[] = []
    keys.forEach(k => {
        const eventName = k
        const eventFunc = events[k]
        if (!eventName || typeof eventFunc !== 'function') {
            return
        }
        eventNames.push(eventName)
        ima.addEventListener(eventName, evt => {
            eventFunc(player, evt)
        })
    })
}

type VideoJsAdsProps = {
    options
    onImaEvents
    autoplay?: boolean
    parent: rtlVideoJs
}

class VideoJsAds {
    parent: rtlVideoJs
    options: any
    canPlayAfterAd = true
    constructor({ options, onImaEvents, autoplay, parent }: VideoJsAdsProps) {
        this.options = options
        this.parent = parent
        // inside try/catch because of possible errors due to adblockers
        try {
            const { player, elements, scheduler } = this.parent
            const adsManagerLoadedCallback = adsManagerLoadedCallbackfactory(parent, {
                ...onImaEvents,
                start: (rtlPlayer, evt) => {
                    this.incrementAdPlayCount()
                    // @NOTE: there must be a more elegant way of getting the ima container
                    // or for setting zIndex to something other than 1111
                    // but this works, so leave it at that
                    // although this might be the role of RtlVideoElements class, this is an ads related problem, so it's solved here
                    const imaContainers = Array.from(
                        rtlPlayer.elements.container.getElementsByClassName('ima-ad-container')
                    ) as HTMLDivElement[]
                    imaContainers.forEach(e => (e.style.zIndex = '1'))

                    // Don't forget to call onStart callback if it exists
                    if (typeof onImaEvents.start === 'function') {
                        onImaEvents.start(rtlPlayer, evt)
                    }
                }
            })

            // @NOTE: if google.ima is not defined, it means that adblocker is enabled or you forgot to include Script tag in _document
            if (!window.google?.ima?.VERSION) {
                console.error('google.ima is not defined')
                return
            }

            // @NOTE: if ima is not a function, it means that videojs-ima plugin is not loaded or hasn't initialized properly
            // @ts-expect-error: ima plugin is not typed
            if (typeof player.ima !== 'function') {
                console.error('videojs-ima plugin is not loaded')
                return
            }

            // console.log("gtmVIdeo adsManagerLoadedCallback", onImaEvents,adsManagerLoadedCallback)

            const preroll = options.positions.find(p => p.position === 'pre' || p.offset === 0)
            this.currentAd = preroll
            // @ts-expect-error: ima plugin is not typed
            player.ima({
                id: elements.vastContainer.id,
                // debug: true,
                adTagUrl: preroll?.url,
                adsManagerLoadedCallback,
                disableFlashAds: true,
                showControlsForJSAds: true,
                showCountdown: true,
                vastLoadTimeout: 2000,
                adLabel: 'Oglas',
                adLabelNofN: 'od',
                locale: 'hr'
                // adsRequest: {
                //     // this forces videojs-ima to use vpa=click and vpmute=0 params in request
                //     j: 'click',
                //     l: 'unmuted'
                // }
            })

            player.on('adstart', () => {
                scheduler.blockPlayPercentageCallbacks()
            })
            player.on('adend', () => {
                scheduler.unblockPlayPercentageCallbacks()
                if (this.canPlayAfterAd) scheduler.play()
            })
            if (autoplay) {
                player.on('readyforpreroll', () => {
                    scheduler.play()
                })
            }

            // @ts-expect-error: ima plugin is not typed
            const ima = player.ima
            ima.initializeAdDisplayContainer()

            const midrolls: any[] = options.positions.filter(
                p => p.position === 'mid' || (p.offset > 0 && p.offset < 100)
            )
            const postroll: any = options.positions.find(p => p.position === 'post' || p.offset >= 100)

            midrolls.forEach(ad => {
                if (ad.offset)
                    this.parent.scheduler.on(
                        ad.offset,
                        () => {
                            // console.log('playing midroll', ad)
                            this.playAdRoll(ad)
                        },
                        false
                    )
            })
            if (postroll?.url) {
                this.parent.scheduler.on(
                    99,
                    () => {
                        this.canPlayAfterAd = false
                        this.playAdRoll({ ...postroll, offset: 99 })
                    },
                    false
                )
            }
        } catch (e) {
            console.error('vast error', e)
        }
    }
    currentAd: any
    incrementAdPlayCount = () => {
        if (!this.currentAd) return null
        if (typeof this.currentAd.playCount !== 'number') this.currentAd.playCount = 0
        this.currentAd.playCount += 1
        return this.currentAd.playCount
    }
    getCurrentAd() {
        return this.currentAd
    }
    playAdRoll = (ad: any) => {
        const { player } = this.parent
        // console.log("gtmVieo ads",ad)
        this.currentAd = ad
        // @ts-expect-error: ima plugin is not typed
        const { ima } = player

        // const currentTime = player.currentTime()
        // console.log('playing adroll...', ad)
        // ima.setContentWithAdTag(null, ad.url, false)
        ima.changeAdTag(ad.url)
        ima.initializeAdDisplayContainer()
        ima.requestAds()
        // if (ad.offset < 100) {
        //     player.currentTime(currentTime)
        //     player.play()
        // }
    }
}
export default VideoJsAds
