import React, { memo } from 'react'
import { PropTypes } from 'prop-types'

import { withErrorBoundary } from '../../components/ErrorBoundary/ErrorBoundary.component'

function SofascorePostController({ url, height }) {
    return (
        <iframe
            title="Sofascore table"
            width="100%"
            height={height}
            src={url}
            style={{ height: `calc(${height}px + 30px)` }}
        />
    )
}

SofascorePostController.propTypes = {
    url: PropTypes.string,
    height: PropTypes.string
}

SofascorePostController.defaultProps = {
    url: undefined,
    height: undefined
}

export default withErrorBoundary(memo(SofascorePostController), {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[SofascorePostController]: ', error, componentStack)
    }
})
