/* eslint-disable camelcase */
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'
import { useInnerHtml } from '@hmn/rtl-web-core/hooks'

import { Frame, Graphic, graphicVariants } from '../../../Decoration/components'
import { Image, imageRatioVariants } from '../../../Image'
import styles from './Highlighted.style'
import HighlightedSignature from './Signature/Signature.component'

const ArticleHighlightedStyled = styled.div(props => ({ ...styles(props) }))

function ArticleHighlighted({
    className,
    title,
    content,
    author,
    authorDesc,
    image,
    imageWidth,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    imageHeight,
    variant,
    titleTagComponent: TitleTag,
    ...rest
}) {
    const titleHtmlProps = useInnerHtml(title)
    const contentHtmlProps = useInnerHtml(content)

    if (!image && !content) {
        return null
    }

    return (
        <ArticleHighlightedStyled className={className} imageWidth={imageWidth} variant={variant} {...rest}>
            <div className="sectionContent">
                {['gamma', 'delta', 'epsilon'].includes(variant) && (
                    <Graphic
                        className="sectionContent_graphic"
                        variant={graphicVariants.GAMMA}
                        widthXs={110}
                        widthMd={190}
                        heightXs={32}
                        heightMd={52}
                    />
                )}
                {title && <TitleTag className="sectionContent_title" {...titleHtmlProps} />}
                {content && <div className="sectionContent_text" {...contentHtmlProps} />}
                <HighlightedSignature author={author} authorDesc={authorDesc} />
            </div>
            {image && ['alpha', 'beta'].includes(variant) && (
                <div className="sectionImage">
                    <Image
                        image={image}
                        variation={imageRatioVariants.CUSTOM_4x5}
                        width={imageWidth}
                        // height={imageHeight}
                        alt={title}
                        fullHeight
                    />
                    <Frame className="sectionImage_frame" />
                    <Graphic
                        className="sectionImage_graphic"
                        variant={variant === 'alpha' ? graphicVariants.ZETA : graphicVariants.EPSILON}
                        widthXs={variant === 'alpha' ? 144 : 82}
                        widthMd={variant === 'alpha' ? 277 : 132}
                        heightXs={variant === 'alpha' ? 60 : 82}
                        heightMd={variant === 'alpha' ? 86 : 112}
                    />
                </div>
            )}
        </ArticleHighlightedStyled>
    )
}

const articleHighlightedVariants = Object.freeze({
    ALPHA: 'alpha',
    BETA: 'beta',
    GAMMA: 'gamma',
    DELTA: 'delta',
    EPSILON: 'epsilon'
})

ArticleHighlighted.propTypes = {
    title: PropTypes.string,
    titleTagComponent: PropTypes.elementType,
    content: PropTypes.string,
    author: PropTypes.string,
    authorDesc: PropTypes.string,
    image: PropTypes.string,
    imageWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    imageHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    className: PropTypes.string,
    variant: PropTypes.oneOf([...Object.values(articleHighlightedVariants)])
}

ArticleHighlighted.defaultProps = {
    title: undefined,
    titleTagComponent: 'h2',
    content: undefined,
    author: undefined,
    authorDesc: undefined,
    image: undefined,
    imageWidth: 650,
    imageHeight: 812,
    className: undefined,
    variant: articleHighlightedVariants.ALPHA
}

export { articleHighlightedVariants }

export default withErrorBoundary(ArticleHighlighted, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[ArticleHighlighted]: ', error, componentStack)
    }
})
