import PropTypes from 'prop-types'

function AmpFacebook({ width, height, url }) {
    return (
        url && <amp-facebook width={width.toString()} height={height.toString()} layout="responsive" data-href={url} />
    )
}

AmpFacebook.propTypes = {
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    url: PropTypes.string
}

AmpFacebook.defaultProps = {
    width: 552,
    height: 310,
    url: undefined
}
export default AmpFacebook
