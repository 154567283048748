import React from 'react'
import { useAmp } from 'next/amp'
import dynamic from 'next/dynamic'
import PropTypes from 'prop-types'

import { EmbedSocial } from '../../components/HtmlContent/HtmlContent.style'

const TelegramPostController = dynamic(
    () =>
        // eslint-disable-next-line import/extensions
        import('./TelegramPost.controller'),
    { ssr: false }
)
export function CommonTelegramEmbed({ url }) {
    const isAmp = useAmp()

    if (!url) {
        return null
    }

    return (
        <EmbedSocial className="u-paddingHeight">
            {isAmp ? (
                <p>
                    <a href={url} title="Telegram post">
                        Pogledaj na Telegram aplikaciji
                    </a>
                </p>
            ) : (
                <TelegramPostController url={url} />
            )}
        </EmbedSocial>
    )
}
CommonTelegramEmbed.propTypes = {
    url: PropTypes.string
}
CommonTelegramEmbed.defaultProps = {
    url: undefined
}
