import PropTypes from 'prop-types'

function AmpSoundcloud({ height, id }) {
    return id && <amp-soundcloud data-trackid={id} layout="fixed-height" height={height.toString()} />
}

AmpSoundcloud.propTypes = {
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    id: PropTypes.string
}

AmpSoundcloud.defaultProps = {
    height: 150,
    id: undefined
}
export default AmpSoundcloud
