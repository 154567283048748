import React from 'react'
import clsx from 'clsx'
import { useAmp } from 'next/amp'
import PropTypes from 'prop-types'

import { EmbedSocial } from '../../components/HtmlContent/HtmlContent.style'
import RedditPostController from './RedditPost.controller'

export function CommonRedditEmbed({ url, height }) {
    const isAmp = useAmp()

    if (!url) {
        return null
    }

    return (
        <EmbedSocial className={clsx(!isAmp && 'u-paddingHeight')}>
            <RedditPostController url={url} height={height || 500} />
        </EmbedSocial>
    )
}
CommonRedditEmbed.propTypes = {
    url: PropTypes.string,
    height: PropTypes.number
}
CommonRedditEmbed.defaultProps = {
    url: undefined,
    height: undefined
}
