// import { EventHandler } from 'react'

const addClassNamesToElement = (element: HTMLElement, classNames?: string | string[]) => {
    if (!classNames) {
        return
    }
    if (Array.isArray(classNames)) {
        element.classList.add(...classNames)
        return
    }
    element.classList.add(classNames)
}

const removeClasNamesFromElement = (element: HTMLElement, classNames?: string | string[]) => {
    if (!classNames) {
        return
    }
    if (Array.isArray(classNames)) {
        element.classList.remove(...classNames)
        return
    }
    element.classList.remove(classNames)
}

const toArray = (value: string | string[] | undefined) => {
    if (!value) {
        return []
    }
    if (Array.isArray(value)) {
        return value
    }
    return [value]
}

const mergeClassNames = (classNames?: string | string[], additionalClassNames?: string | string[]) => {
    if (typeof classNames === 'undefined' && typeof additionalClassNames === 'undefined') {
        return []
    }
    if (!classNames) {
        return toArray(additionalClassNames)
    }
    if (!additionalClassNames) {
        return toArray(classNames)
    }
    return [...toArray(classNames), ...toArray(additionalClassNames)]
}

const createElement = (
    tagName = 'div',
    {
        classNames,
        parent,
        id,
        content,
        style
    }: {
        classNames?: string | string[]
        parent?: Element
        id?: string
        content?: string | Element
        style?: any
    }
) => {
    const element = document.createElement(tagName)
    if (id) {
        element.id = id
    }
    if (content) {
        if (typeof content === 'string') {
            element.innerHTML = content
        } else {
            element.appendChild(content)
        }
    }
    if (style) {
        Object.assign(element.style, style)
    }
    addClassNamesToElement(element, classNames)
    if (parent) {
        parent.appendChild(element)
    }
    return element
}
export type VideoJsElementsOptions = {
    parentContainer: HTMLElement
    classNames?: {
        container?: string | string[]
        videoElement?: string | string[]
        closeButton?: string | string[]
        muteButton?: string | string[]
        fixed?: string | string[]
    }
    ids?: {
        vastContainer?: string
    }
    on: {
        [key: string]: (...args: unknown[]) => void
    }
}
class VideoJsElements {
    parent: Element
    container: HTMLElement
    videoElement: HTMLElement
    vastContainer: HTMLElement
    closeButton: HTMLElement
    muteButton: HTMLElement
    fixedClassName = ['fixed']
    isFixed = false
    constructor({ parentContainer, classNames, ids, on }: VideoJsElementsOptions) {
        if (!parentContainer) {
            throw new Error('VideoJsElements: parentContainer is undefined')
        }
        if (typeof window === 'undefined') {
            throw new Error('VideoJsElements: window is undefined')
        }
        this.parent = parentContainer

        if (classNames?.fixed) {
            this.fixedClassName = toArray(classNames.fixed)
        }

        this.container = createElement('div', {
            parent: parentContainer,
            classNames: mergeClassNames(classNames?.container, 'video-js-container')
        })

        this.closeButton = createElement('button', {
            parent: this.container,
            classNames: mergeClassNames(classNames?.closeButton, ['close-button', 'hidden']),
            id: 'VideoJs_close_button',
            content: '×',
            style: { display: 'none' }
        })

        const clickTouchHangler = event => {
            event.preventDefault()
            event.stopPropagation()
            this.setFixed(false)
            if (on?.close) {
                on.close(event)
            }
        }

        this.closeButton.addEventListener('click', clickTouchHangler)
        this.closeButton.addEventListener('touchstart', clickTouchHangler)

        this.videoElement = createElement('video-js', {
            parent: this.container,
            classNames: mergeClassNames(classNames?.videoElement, 'vjs-big-play-centered')
        })

        this.vastContainer = createElement('div', {
            parent: this.container,
            classNames: mergeClassNames(classNames?.container, 'vast-container'),
            id: ids?.vastContainer || 'VideoJs_vast_container'
        })

        this.muteButton = createElement('button', {
            parent: this.container,
            classNames: mergeClassNames(classNames?.container, ['vjs-mute-button-custom', 'vjs-button-muted'])
        })
    }
    setFixed(fixed: boolean) {
        // console.log("videojs setFixed",fixed)
        if (this.isFixed === fixed) {
            return
        }
        if (fixed) {
            addClassNamesToElement(this.container, this.fixedClassName)
            this.closeButton.style.display = 'block'
        } else {
            removeClasNamesFromElement(this.container, this.fixedClassName)
            this.closeButton.style.display = 'none'
        }
        this.isFixed = fixed
    }
}

export default VideoJsElements
