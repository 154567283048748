import { toRem, toRems } from '../../../../helpers/theme'

/* eslint-disable quote-props, no-dupe-keys, max-len */
const style = ({ theme }) => {
    const config = {
        xs: {
            flexDirection: 'column',
            width: '80%',
            margin: toRems([30, 0, 30, 0]),
            graphic: {
                width: '80%',
                bottom: toRem(-20)
            },
            content: {
                width: '100%',
                marginBottom: toRem(22),
                gridTemplateAreas: `'icon title title title title title'
                'text text text text text text'`,
                text: {
                    fontSize: toRem(16)
                }
            }
        },
        md: {
            flexDirection: 'row',
            width: '90%',
            margin: 0,
            graphic: {
                width: '25%',
                bottom: toRem(-30)
            },
            content: {
                width: '70%',
                marginBottom: 0,
                gridTemplateAreas: `'icon title title title title title'
                'icon text text text text text'`,
                text: {
                    fontSize: toRem(18)
                }
            }
        }
    }

    return {
        position: 'relative',
        display: 'flex',
        border: `${toRem(1)} solid ${theme.colors.article.block.border}`,
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: toRem(140),
        marginBottom: toRem(50),
        width: '100%',
        '& .ctaBlock': {
            display: 'flex',
            flexDirection: config?.xs?.flexDirection,
            width: config?.xs?.width,
            margin: config?.xs?.margin,
            [theme.breakpoints.up('md')]: {
                flexDirection: config?.md?.flexDirection,
                width: config?.md?.width,
                margin: config?.md?.margin,
                alignItems: 'center',
                justifyContent: 'space-between'
            },
            '&_content': {
                display: 'grid',
                gridTemplateColumns: 'repeat(6, 1fr)',
                gridTemplateRows: '1fr 1fr',
                marginBottom: config?.xs?.content?.marginBottom,
                width: config?.xs?.content?.width,
                gridTemplateAreas: config?.xs?.content?.gridTemplateAreas,
                [theme.breakpoints.up('md')]: {
                    marginBottom: config?.md?.content?.marginBottom,
                    width: config?.md?.content?.width,
                    gridTemplateAreas: config?.md?.content?.gridTemplateAreas
                }
            },
            '&_iconWrap': {
                gridArea: 'icon',
                display: 'flex',
                alignItems: 'center'
            },
            '&_icon': {
                width: toRem(70),
                height: toRem(70),
                color: theme.colors.product?.card?.badge?.color || theme.colors.devWarning,
                '& path': {
                    fill: 'currentColor'
                },
                marginRight: '16px'
            },
            '&_title': {
                gridArea: 'title',
                display: 'flex',
                alignItems: 'flex-end',
                fontFamily: theme.typography.fontFamilyAlt,
                fontStyle: theme.typography.font.italic,
                fontWeight: theme.typography.fontWeight.bold,
                lineHeight: 1.6,
                fontSize: toRem(24),
                color: theme.colors.text.default
            },
            '&_text': {
                gridArea: 'text',
                width: '100%',
                lineHeight: 1.8,
                fontSize: config?.xs?.content?.text?.fontSize,
                [theme.breakpoints.up('md')]: {
                    fontSize: config?.md?.content?.text?.fontSize
                }
            },
            '&_button': {
                height: toRem(50)
            },
            '&_graphic': {
                position: 'absolute',
                width: config?.xs?.graphic?.width,
                bottom: config?.xs?.graphic?.bottom,
                [theme.breakpoints.up('md')]: {
                    width: config?.md?.graphic?.width,
                    bottom: config?.md?.graphic?.bottom,
                    marginLeft: '63%'
                }
            }
        }
    }
}
export default style
