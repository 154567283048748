import PropTypes from 'prop-types'

function AmpTikTok({ width, height, id }) {
    return id && <amp-tiktok width={width.toString()} height={height.toString()} data-src={id} />
}

AmpTikTok.propTypes = {
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    id: PropTypes.string
}

AmpTikTok.defaultProps = {
    width: 325,
    height: 575,
    id: undefined
}
export default AmpTikTok
