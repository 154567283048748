import PropTypes from 'prop-types'

function AmpVimeo({ width, height, videoId }) {
    return (
        videoId && (
            <amp-vimeo data-videoid={videoId} layout="responsive" width={width.toString()} height={height.toString()} />
        )
    )
}

AmpVimeo.propTypes = {
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    videoId: PropTypes.string
}

AmpVimeo.defaultProps = {
    width: 16,
    height: 9,
    videoId: undefined
}
export default AmpVimeo
