import { toRem, toRems } from '../../../../helpers'

const style = ({ theme }) => {
    const config = {
        color: {
            default: theme.colors.primary,
            hover: theme.colors.zenaDarkPink,
            visited: theme.colors.grey600
        }
    }
    return {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        a: {
            fontSize: toRem(12),
            lineHeight: toRem(24),
            padding: toRems([10, 20]),
            color: config?.color.default || theme.colors.devWarning,
            borderBottom: `solid 1px rgba(0,0,0,0)`,
            fontWeight: 700,
            textTransform: 'uppercase',
            letterSpacing: '0.25em',
            margin: 'auto',
            '&:hover': {
                color: config?.color.hover || theme.colors.devWarning,
                borderBottom: `solid 1px ${config?.color.hover || theme.colors.devWarning}`
            },
            '&:visited': {
                color: config?.color.visited || theme.colors.devWarning,
                borderBottom: 'none'
            }
        }
    }
}

export default style
