/* eslint-disable @typescript-eslint/naming-convention */
import { createStyledComponent } from '../../helpers/components/createStyledComponent'
// import { toRem } from '../../helpers/theme'
// @NOTE: leave this in for later
// const configVariants = Object.freeze({
//     'zena.hr': {
//         fixed: {
//             sm: {
//                 top: toRem(100),
//                 width: toRem(250),
//                 minWidth: toRem(200),
//                 height: toRem(150)
//             },
//             md: {
//                 top: toRem(100),
//                 width: toRem(400),
//                 minWidth: toRem(250),
//                 height: toRem(250)
//             }
//         }
//     },
//     'net.hr': {
//         fixed: {
//             sm: {
//                 top: toRem(50),
//                 width: toRem(350),
//                 minWidth: toRem(250),
//                 height: toRem(196)
//             },
//             md: {
//                 top: toRem(100),
//                 width: toRem(400),
//                 minWidth: toRem(250),
//                 height: toRem(225)
//             }
//         }
//     },
//     'rtl.hr': {
//         fixed: {
//             sm: {
//                 top: toRem(50),
//                 width: toRem(350),
//                 minWidth: toRem(250),
//                 height: toRem(196)
//             },
//             md: {
//                 top: toRem(100),
//                 width: toRem(400),
//                 minWidth: toRem(250),
//                 height: toRem(225)
//             }
//         }
//     }
// })

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const style = () => ({
    '.ima-controls-div': {
        overflow: 'visible',
        height: '2.5rem',
        // '.ima-seek-bar-div': {},
        // '.ima-play-pause-div': {},
        // '.ima-mute-div': {},
        // '.ima-fullscreen-div': {},
        '& .ima-play-pause-div, & .ima-mute-div, & .ima-fullscreen-div': {
            top: '1rem'
        },
        '& .ima-seek-bar-div': {
            top: 'auto',
            bottom: '0'
        }
    }
})

export default createStyledComponent({ styles: style })
