// @TODO: ArticleShare - maybe the component will be removed
import { useMemo } from 'react'
import styled from '@emotion/styled'
import dayjs from 'dayjs'
import { useAmp } from 'next/amp'
import PropTypes from 'prop-types'

import { Graphic, graphicFillVariants, graphicVariants } from '../../../Decoration/components'
import { HtmlContent } from '../../../HtmlContent'
import { SocialShare } from '../../../Social/components'
import styles from './Meta.style'

const ArticleMetaStyled = styled.div(props => ({ ...styles(props) }))

function ArticleMeta({ author, date, shareUrl, title, source, sourceTitle, className, ...rest }) {
    const articleDate = useMemo(() => `${dayjs(date).format('D.M.YYYY.')}`, [date])
    const isAmp = useAmp()
    return (
        <ArticleMetaStyled className={className} {...rest}>
            {(author || date) && (
                <div className="articleMeta_inner">
                    {author && <div className="articleMeta_author">{author?.title}</div>}
                    {date && <div className="articleMeta_date">{articleDate}</div>}
                </div>
            )}
            {source && (
                <div className="articleMeta_sourceWrap">
                    <div className="articleMeta_sourceTitle">{sourceTitle}:</div>
                    <HtmlContent data={source} />
                </div>
            )}
            <SocialShare
                title={title}
                social={[isAmp ? '' : 'url', 'facebook', 'messenger', 'viber', 'whatsapp']}
                shareUrl={shareUrl}
            />
            <Graphic
                className="articleMeta_graphic"
                variant={graphicVariants.ETA}
                fillVariant={graphicFillVariants.ALTERNATIVE}
            />
        </ArticleMetaStyled>
    )
}

ArticleMeta.propTypes = {
    className: PropTypes.string,
    author: PropTypes.oneOfType([
        PropTypes.shape({
            title: PropTypes.string
        }),
        PropTypes.string
    ]),
    date: PropTypes.string,
    shareUrl: PropTypes.string,
    source: PropTypes.string,
    sourceTitle: PropTypes.string,
    title: PropTypes.string
}

ArticleMeta.defaultProps = {
    className: undefined,
    author: undefined,
    date: undefined,
    shareUrl: undefined,
    source: undefined,
    sourceTitle: 'Foto',
    title: undefined
}

export default ArticleMeta
