import { forwardRef } from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { HtmlContent } from '../../../HtmlContent'
import styles from './Content.style'

const ContentStyled = styled.article(props => ({ ...styles(props) }))

const Content = forwardRef(({ className, content, insertElements, endDecoration, children, ...rest }, ref) => (
    <ContentStyled className={className} {...rest} ref={ref}>
        {children || <HtmlContent endDecoration={endDecoration} data={content} insertElements={insertElements} />}
    </ContentStyled>
))

Content.propTypes = {
    className: PropTypes.string,
    content: PropTypes.string,
    endDecoration: PropTypes.bool,
    insertElements: PropTypes.oneOfType([() => null, PropTypes.arrayOf(PropTypes.object)])
}

Content.defaultProps = {
    className: undefined,
    content: undefined,
    endDecoration: false,
    insertElements: undefined
}

export default Content
