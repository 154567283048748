// @TODO: Share dropdown (but maybe the whole component will be removed)

import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { Icon, ShareIcon } from '../../../Icon'
import styles from './Share.style'

const VideoShareStyled = styled.div(props => ({ ...styles(props) }))

function VideoShare({ shareUrl, shareText, className, ...rest }) {
    if (!shareUrl) {
        return null
    }

    return (
        <VideoShareStyled className={className} {...rest}>
            <a href={shareUrl} className="videoShare_inner" title={shareText}>
                <Icon icon={ShareIcon} />
                <div className="videoShare_text">{shareText}</div>
            </a>
        </VideoShareStyled>
    )
}

VideoShare.propTypes = {
    className: PropTypes.string,
    shareText: PropTypes.string,
    shareUrl: PropTypes.string
}

VideoShare.defaultProps = {
    className: undefined,
    shareText: 'podijeli',
    shareUrl: undefined
}

export default VideoShare
