import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'

import { ConditionalWrapper } from '../ConditionalWrapper'
import { CheckMarkIcon, CommentIcon, Icon, ReviewIcon, ScoreIcon, ShareIcon } from '../Icon'
import { Link } from '../Link'
import styles from './MetaButton.style'

const MetaButtonStyled = styled.button(props => ({ ...styles(props) }))

// @TODO: should probably add a hover state
// @INFO: Icon must be normalized to viewBox="0 0 32 32"
const getIcon = variant =>
    ({
        share: ShareIcon,
        comment: CommentIcon,
        score: ScoreIcon,
        review: ReviewIcon,
        advice: CommentIcon,
        answer: CheckMarkIcon
    })[variant]

function MetaButton({
    className,
    href,
    hrefAs,
    hrefIsExternal,
    title,
    iconValue,
    onClick,
    variant,
    color,
    size,
    minValue
}) {
    if (
        !title ||
        (['comment', 'review', 'score'].includes(variant) && (!iconValue || parseInt(iconValue, 10) < minValue))
    ) {
        return null
    }
    return (
        <MetaButtonStyled
            className={className}
            color={color}
            size={size}
            variant={variant}
            onClick={onClick}
            type="button">
            <ConditionalWrapper
                condition={!!href}
                Wrapper={Link}
                href={href}
                as={hrefAs}
                isExternal={hrefIsExternal}
                title={title}>
                <div className="metaButton_inner">
                    <div className="metaButton_icon">
                        <Icon icon={getIcon(variant)} />
                        {(iconValue || iconValue === 0) && (
                            <div className="metaButton_iconValue">
                                <span>{iconValue}</span>
                            </div>
                        )}
                    </div>
                    {title && (
                        <div className="metaButton_title">
                            <span>{title}</span>
                        </div>
                    )}
                </div>
            </ConditionalWrapper>
        </MetaButtonStyled>
    )
}

const metaButtonVariants = Object.freeze({
    SHARE: 'share',
    COMMENT: 'comment',
    REVIEW: 'review',
    SCORE: 'score',
    ADVICE: 'advice',
    ANSWER: 'answer'
})
const metaButtonColors = Object.freeze({
    DEFAULT: 'default',
    GREEN: 'green'
})
const metaButtonSizes = Object.freeze({
    NORMAL: 'normal',
    LARGE: 'large',
    XL: 'xl'
})
MetaButton.propTypes = {
    className: PropTypes.string,
    href: PropTypes.string,
    hrefAs: PropTypes.string,
    hrefIsExternal: PropTypes.bool,
    iconValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    title: PropTypes.string,
    onClick: PropTypes.func,
    variant: PropTypes.oneOf([...Object.values(metaButtonVariants)]),
    color: PropTypes.oneOf([...Object.values(metaButtonColors)]),
    size: PropTypes.oneOf([...Object.values(metaButtonSizes)]),
    minValue: PropTypes.number
}

MetaButton.defaultProps = {
    className: undefined,
    href: undefined,
    hrefAs: undefined,
    hrefIsExternal: false,
    iconValue: undefined,
    title: undefined,
    onClick: () => {},
    variant: metaButtonVariants.COMMENT,
    color: metaButtonColors.DEFAULT,
    size: metaButtonSizes.NORMAL,
    minValue: 3
}

export { metaButtonVariants, metaButtonColors, metaButtonSizes }

export default withErrorBoundary(MetaButton, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[MetaButton]: ', error, componentStack)
    }
})
