import React from 'react'

import { YoutubeEmbed } from '../Embed'
import { CommonInstagramEmbed, CommonRedditEmbed, CommonTelegramEmbed, CommonTikTokEmbed, CommonTwitterEmbed } from '.'
import { EmbedIFrame, EmbedSoundCloud, EmbedVideo } from './HtmlContent.style'

function Youtube(attributes) {
    const { url, ...attrs } = attributes
    return (
        <EmbedVideo {...attrs}>
            <YoutubeEmbed url={url} />
        </EmbedVideo>
    )
}
const vimeoRegex = /(?:vimeo)\.com.*(?:videos|video|channels|)\/([\d]+)/i
function Vimeo(attributes) {
    const { url } = attributes
    const parsed = url?.match(vimeoRegex)

    if (!parsed?.[1]) {
        return null
    }
    return (
        <EmbedVideo id="embed_vimeo">
            {/* eslint-disable-next-line react/no-unknown-property */}
            <iframe src={`//player.vimeo.com/video/${parsed[1]}`} title={url} allowFullScreen loading="lazy" />
        </EmbedVideo>
    )
}
function SoundCloud(attributes) {
    const { url } = attributes

    // @TODO: this is needed for AMP, if id is not in url, it needs to be pulled from the Soundcloud API (https://stackoverflow.com/questions/26289927/how-to-get-track-id-from-url-using-the-soundcloud-api)
    // best done in cms, 2nd option midd, most hacky option frontend ssr
    // const id = useMemo(() => url?.split('?')?.[0].match(/(?<=\/)\d+(?=\/|$)/gim)?.[0], [url])
    if (!url) {
        return null
    }
    return (
        <EmbedSoundCloud>
            <iframe src={`https://w.soundcloud.com/player/?url=${url}`} title={url} loading="lazy" />
        </EmbedSoundCloud>
    )
}
const IFRAME_SCROLLBAR_HEIGHT = 30
function Iframe(attributes) {
    const { url, height } = attributes

    const parsedHeight = Number.isNaN(parseFloat(height)) ? 400 : parseFloat(height) + IFRAME_SCROLLBAR_HEIGHT

    const desktopHeight = (height && parseFloat(height.split('.')[0])) || 400
    const mobileHeight = (height && parseFloat(height.split('.')[1])) || desktopHeight

    return (
        <EmbedIFrame parsedHeight={parsedHeight} desktopHeight={desktopHeight} mobileHeight={mobileHeight}>
            <div className="embed_iframe_container" style={{ overflow: 'hidden' }}>
                <iframe
                    src={url}
                    title={url}
                    width="100%"
                    height="100%"
                    style={{ marginBottom: `-${IFRAME_SCROLLBAR_HEIGHT}px` }}
                    loading="lazy"
                />
            </div>
        </EmbedIFrame>
    )
}

const embeds = new Map()
embeds.set('youtube', attributes => <Youtube {...attributes} />)
embeds.set('instagram', attributes => <CommonInstagramEmbed {...attributes} />)
embeds.set('telegram', attributes => <CommonTelegramEmbed {...attributes} />)
embeds.set('reddit', attributes => <CommonRedditEmbed {...attributes} />)
embeds.set('twitter', attributes => <CommonTwitterEmbed {...attributes} />)
embeds.set('tiktok', attributes => <CommonTikTokEmbed {...attributes} />)
embeds.set('vimeo', attributes => <Vimeo {...attributes} />)
embeds.set('soundcloud', attributes => <SoundCloud {...attributes} />)
embeds.set('iframe', attributes => <Iframe {...attributes} />)

export default embeds
