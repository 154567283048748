import { useMemo } from 'react'

import { useGTM } from '../../hooks'

export const useVideoJsGTM = ({ url, isEmbed }) => {
    const { sendDataToGTM } = useGTM()
    return useMemo(
        () => ({
            send: sendDataToGTM,
            onPlay: (data = {}) =>
                sendDataToGTM({
                    event: 'VideoJs',
                    eventCategory: 'VideoJs Video Player',
                    eventAction: 'play',
                    eventLabel: url,
                    videoType: isEmbed ? 'Embed' : 'Native',
                    ...data
                }),
            onPause: (data = {}) =>
                sendDataToGTM({
                    event: 'VideoJs',
                    eventCategory: 'VideoJs Video Player',
                    eventAction: 'pause',
                    eventLabel: url,
                    videoType: isEmbed ? 'Embed' : 'Native',
                    ...data
                }),
            onAd: (data = {}) =>
                sendDataToGTM({
                    event: 'VideoJs',
                    eventCategory: 'VideoJs Video Player',
                    eventLabel: url,
                    videoType: isEmbed ? 'Embed' : 'Native',
                    ...data
                }),
            onComplete: (data = {}) =>
                sendDataToGTM({
                    event: 'VideoJs',
                    eventCategory: 'VideoJs Video Player',
                    eventAction: 'complete',
                    eventLabel: url,
                    videoType: isEmbed ? 'Embed' : 'Native',
                    ...data
                })
        }),
        [url, isEmbed, sendDataToGTM]
    )
}
