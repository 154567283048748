// @TODO: VideoShare - maybe the component will be removed

import { useMemo } from 'react'
import styled from '@emotion/styled'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'

import { Graphic, graphicFillVariants, graphicVariants } from '../../../Decoration/components'
import { SocialShare } from '../../../Social/components'
import styles from './Meta.style'

const VideoMetaStyled = styled.div(props => ({ ...styles(props) }))

function VideoMeta({ author, date, shareUrl, title, className, ...rest }) {
    const videoDate = useMemo(() => `${dayjs(date).format('D.M.YYYY.')}`, [date])

    return (
        <VideoMetaStyled className={className} {...rest}>
            {(author || date) && (
                <div className="videoMeta_inner">
                    {author && <div className="videoMeta_author">{author}</div>}
                    {date && <div className="videoMeta_date">{videoDate}</div>}
                </div>
            )}
            <SocialShare
                title={title}
                social={['url', 'facebook', 'messenger', 'viber', 'whatsapp']}
                shareUrl={shareUrl}
            />
            <Graphic
                className="videoMeta_graphic"
                variant={graphicVariants.ETA}
                fillVariant={graphicFillVariants.ALTERNATIVE}
            />
        </VideoMetaStyled>
    )
}

VideoMeta.propTypes = {
    className: PropTypes.string,
    author: PropTypes.string,
    date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    shareUrl: PropTypes.string,
    title: PropTypes.string
}

VideoMeta.defaultProps = {
    className: undefined,
    author: undefined,
    date: undefined,
    shareUrl: undefined,
    title: undefined
}

export default VideoMeta
