// @TODO: swipe implementation
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useUIDSeed } from 'react-uid'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import SwiperCore, { Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import { Media } from '@hmn/rtl-web-core/components/Media'
import { useBreakpoints, useModal, usePoll } from '@hmn/rtl-web-core/hooks'

import useUser from '../../../../hooks/useUser'
import { Button, buttonVariants } from '../../../Button'
import { Graphic, graphicVariants } from '../../../Decoration/components'
import { ArrowLeftIcon, ArrowRightPollIcon, Icon, UnionIcon } from '../../../Icon'
import { ProfilePopup } from '../../../ProfilePopup'
import { PollItem } from './Item'
import styles from './Poll.style'

const getCookieName = id => `poll-${id}`

SwiperCore.use([Navigation])
const ArticlePollStyled = styled.div(props => ({ ...styles(props) }))

function ArticlePoll({ id, className, variant, isLessThanHalf, ...rest }) {
    const { open: openModal } = useModal('profile-modal')
    const { isValid, isLoggedIn } = useUser()
    const { data: pollItem, addVote } = usePoll({ id })
    const {
        getValues,
        handleSubmit,
        register,
        setValue,
        watch,
        formState: { isSubmitting }
    } = useForm()
    const swiperComponent = useRef()
    const uid = useUIDSeed()
    const [isDesktop] = useBreakpoints('md')
    const [hasBeenSubmited, setHasBeenSubmited] = useState(false)

    useEffect(() => {
        const cookies = window.document?.cookie?.split(';').reduce((all, cookie) => {
            const [key, ...vals] = cookie.trim().split('=')
            all[key] = vals.join('=')
            return all
        }, {})

        setHasBeenSubmited(!!cookies[getCookieName(id)])
    }, [id])

    useEffect(() => {
        watch()
    }, [watch])

    const poll = useMemo(() => {
        if (pollItem) {
            let totalVotes = 0

            if (pollItem.extended_attributes?.poll_answers) {
                totalVotes = pollItem.extended_attributes.poll_answers.reduce(
                    (acc, current) => acc + (current?.votes || 0),
                    0
                )
            }

            return {
                title: pollItem.title,
                allowAnonymous: pollItem.extended_attributes?.poll_allow_anonymous || false,
                answers: pollItem.extended_attributes?.poll_answers,
                totalVotes
            }
        }
        return {}
    }, [pollItem])

    const canSubmitAnswer = (isLoggedIn && isValid) || poll?.allowAnonymous

    const validateAnswer = useCallback(() => {
        const index = poll?.answers?.map(item => item?.answer)?.indexOf(getValues('answer'))

        if (index === -1) {
            return false
        }

        return index
    }, [getValues, poll?.answers])

    const onSubmit = useCallback(async () => {
        if (!canSubmitAnswer) {
            openModal()

            return
        }

        const answerIndex = validateAnswer()

        if (answerIndex === false) {
            // eslint-disable-next-line no-alert
            alert('Molimo odaberite odgovor.')
            return
        }

        const date = new Date()
        // @NOTE: VOTE ONCE A DAY
        date.setDate(date.getDate() + 1)
        date.setHours(0, 0, 0, 0)
        document.cookie = `${getCookieName(id)}=1;expires=${date.toGMTString()}`

        await addVote(answerIndex)

        setHasBeenSubmited(true)
    }, [canSubmitAnswer, addVote, id, openModal, validateAnswer])

    const pollVariant = useMemo(() => {
        if (variant) {
            return variant
        }

        const pollType = pollItem?.extended_attributes?.poll_type
        const pollImage = pollItem?.extended_attributes?.poll_answers[0]?.image
        const pollAnswers = pollItem?.extended_attributes?.poll_answers

        if (pollType === 'regular') {
            if (pollImage) {
                return 'beta'
            }
            return pollAnswers.length > 5 ? 'gamma' : 'alpha'
        }

        return pollImage ? 'epsilon' : 'delta'
    }, [variant, pollItem])

    const showAnswers = hasBeenSubmited

    if (!pollItem?.extended_attributes || !pollItem?.extended_attributes?.published) {
        return null
    }

    const slideLeft = () => {
        const swiper = swiperComponent?.current?.swiper
        if (swiper) {
            swiper.slidePrev()
        }
    }

    const slideRight = () => {
        const swiper = swiperComponent?.current?.swiper
        if (swiper) {
            swiper.slideNext()
        }
    }

    return (
        <ArticlePollStyled className={className} variant={pollVariant} isLessThanHalf={isLessThanHalf} {...rest}>
            <Graphic className="pollGraphics" variant={graphicVariants.THETA} widthMd={30} widthXs={15} />
            <form onSubmit={handleSubmit(onSubmit)} className="pollForm" target="_top">
                <div className="pollForm_header">
                    <h1 className="pollTitle">{poll?.title}</h1>
                    {poll?.answers?.length > 3 && ['epsilon'].includes(pollVariant) && (
                        <Media greaterThan="md" className="pollSlider" scaleDown>
                            <Button
                                className="pollSlider_button"
                                onClick={slideLeft}
                                iconComponent={<Icon icon={ArrowLeftIcon} size={10} />}
                            />
                            <Button
                                className="pollSlider_button"
                                onClick={slideRight}
                                iconComponent={<Icon icon={ArrowRightPollIcon} size={14} />}
                            />
                        </Media>
                    )}
                </div>
                {!['epsilon'].includes(pollVariant) && (
                    <div className="itemsWrapper">
                        {poll?.answers?.map((item, index) => {
                            if (!item.answer && !item.image) {
                                return null
                            }
                            const percentage =
                                showAnswers && poll?.totalVotes
                                    ? ((100 * parseInt(item.votes, 10)) / (poll?.totalVotes || 1) || 0).toFixed()
                                    : null
                            return (
                                <PollItem
                                    key={uid(item?.answer || index)}
                                    title={item.answer}
                                    image={item.image}
                                    pollVariant={pollVariant}
                                    radioGroup="poll"
                                    name={index}
                                    onClick={() => setValue('answer', item?.answer)}
                                    isSelected={getValues('answer') === item?.answer}
                                    votePercentage={percentage}
                                />
                            )
                        })}
                    </div>
                )}
                {['epsilon'].includes(pollVariant) && (
                    <Swiper
                        ref={swiperComponent}
                        className="campaign_swiper"
                        spaceBetween={isDesktop ? 20 : 10}
                        slidesPerView="auto"
                        navigation={{
                            prevEl: '.swiperNav_prev',
                            nextEl: '.swiperNav_next'
                        }}>
                        {poll?.answers?.map((item, index) => {
                            if (!item.answer && !item.image) {
                                return null
                            }
                            const percentage =
                                showAnswers && poll?.totalVotes
                                    ? ((100 * parseInt(item.votes, 10)) / (poll?.totalVotes || 1) || 0).toFixed()
                                    : null

                            return (
                                <SwiperSlide key={uid(item?.answer || index)} className="swiper_slide">
                                    <PollItem
                                        key={uid(item?.answer || index)}
                                        title={item.answer}
                                        image={item.image}
                                        pollVariant={pollVariant}
                                        radioGroup="poll"
                                        name={index}
                                        onClick={() => setValue('answer', item?.answer)}
                                        isSelected={getValues('answer') === item?.answer}
                                        votePercentage={percentage}
                                    />
                                </SwiperSlide>
                            )
                        })}
                    </Swiper>
                )}
                <input style={{ display: 'none' }} {...register('answer')} />
                {!showAnswers ? (
                    <Button
                        type="submit"
                        title="Odgovori"
                        className="pollSubmit"
                        // disabled={isSubmitting}
                        loading={isSubmitting}
                        iconComponent={<Icon icon={UnionIcon} />}
                        variant={buttonVariants.BETA}>
                        Odgovori
                    </Button>
                ) : (
                    <div className="pollTotal">
                        Ukupno odgovora <span className="pollTotal_votes"> {poll?.totalVotes}</span>
                    </div>
                )}
            </form>
            <ProfilePopup profileModalUid="profile-modal" />
        </ArticlePollStyled>
    )
}

export const articlePollVariants = {
    ALPHA: 'alpha',
    BETA: 'beta',
    GAMMA: 'gamma',
    DELTA: 'delta',
    EPSILON: 'epsilon'
}

ArticlePoll.propTypes = {
    className: PropTypes.string,
    variant: PropTypes.oneOf([...Object.values(articlePollVariants)]),
    id: PropTypes.string,
    isLessThanHalf: PropTypes.bool
}

ArticlePoll.defaultProps = {
    className: undefined,
    variant: undefined,
    id: undefined,
    isLessThanHalf: false
}

export default ArticlePoll
