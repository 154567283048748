import PropTypes from 'prop-types'

function AmpInstagram({ width, height, id }) {
    return (
        id && (
            <amp-instagram
                data-shortcode={id}
                layout="responsive"
                width={width.toString()}
                height={height.toString()}
            />
        )
    )
}

AmpInstagram.propTypes = {
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    id: PropTypes.string
}

AmpInstagram.defaultProps = {
    width: 400,
    height: 400,
    id: undefined
}
export default AmpInstagram
