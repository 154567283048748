/* eslint-disable import/extensions */
import { useAmp } from 'next/amp'
import PropTypes from 'prop-types'

import { AmpFacebook } from '@hmn/rtl-web-core/components/Amp'
import { EmbedSocial } from '@hmn/rtl-web-core/components/HtmlContent/HtmlContent.style'
import LazyFacebookItem, {
    lazyInstanceDefaultProps,
    lazyInstancePropTypes
} from '@hmn/rtl-web-core/controllers/FacebookItem/LazyFacebookItem.component'

function LazyFacebookItemZena({ src, ...rest }) {
    if (!src) {
        return null
    }
    return (
        <LazyFacebookItem
            src={src}
            {...rest}
            appId={process.env.NEXT_PUBLIC_FACEBOOK_APP_ID}
            appRootUrl={process.env.NEXT_PUBLIC_APP_ROOT_ZENA}
        />
    )
}

LazyFacebookItemZena.propTypes = lazyInstancePropTypes

LazyFacebookItemZena.defaultProps = lazyInstanceDefaultProps
export function FacebookEmbed({ url }) {
    const isAmp = useAmp()
    const isVideo = url?.indexOf('/video') !== -1 || url?.indexOf('watch') !== -1

    return (
        <EmbedSocial>
            {isAmp ? (
                <AmpFacebook width="552" height="310" url={url} />
            ) : (
                <LazyFacebookItemZena src={url} isPost={!isVideo} isVideo={isVideo} />
            )}
        </EmbedSocial>
    )
}
FacebookEmbed.propTypes = {
    url: PropTypes.string
}
FacebookEmbed.defaultProps = {
    url: undefined
}

export default LazyFacebookItemZena
