import { toRem, toRems } from '../../../../helpers/theme'

/* eslint-disable quote-props, no-dupe-keys, max-len */
const style = ({ theme, imageWidth, variant, halfGreyBackground, ampImageFitCover, isColumnist }) => {
    const config = {
        sm: {
            titleWrap: {
                justifyContent: 'space-between'
            }
        },
        md: {
            variant: {
                alpha: {
                    padding: toRems([50, 0])
                },
                beta: {
                    maxWidth: toRem(1100),
                    margin: '50px auto',
                    padding: 0
                }
            },
            titleWrap: {
                justifyContent: 'center'
            },
            heroWrapper: {
                variant: {
                    alpha: {
                        margin: 'auto'
                    }
                }
            },
            hero: {
                variant: {
                    alpha: {
                        marginBottom: toRem(82)
                    },
                    beta: {
                        marginBottom: 0,
                        height: '100%'
                    }
                }
            }
        }
    }
    return {
        position: 'relative',
        padding: toRems([20, 0, 0, 0]),
        [theme.breakpoints.up('md')]: {
            padding: config?.md?.variant[variant]?.padding,
            maxWidth: config?.md?.variant[variant]?.maxWidth,
            margin: config?.md?.variant[variant]?.margin,
            ...(variant === 'alpha' &&
                halfGreyBackground && {
                    '&::before': {
                        content: '""',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        background: `linear-gradient(90deg, ${theme.colors.background.body.default} 50%, ${theme.colors.background.body.light} 50%)`,
                        width: '100%',
                        height: '65%'
                    }
                }),
            ...(isColumnist && {
                '&::before': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    background: theme.colors.background.body.light,
                    width: '100%',
                    height: '65%'
                }
            }),
            ...(variant === 'beta' && {
                display: 'flex',
                flexDirection: 'row',
                background: theme.colors.background.body.light
            })
        },
        '& .articleHead': {
            // @TODO: connect to layout dimensions for head
            maxWidth: toRem(1215),
            padding: toRems([0, 25]),
            position: 'relative',
            zIndex: theme.zIndex.over,
            ...(variant === 'alpha' && {
                margin: '0 auto',
                textAlign: 'center'
            }),
            ...(variant === 'beta' && {
                flex: 1,
                margin: 'auto',
                padding: toRems([50, 25])
            }),
            ...(isColumnist && {
                [theme.breakpoints.down('sm')]: {
                    textAlign: 'left'
                }
            })
        },
        '& .articleHead_label': {
            ...(variant === 'alpha' && {
                display: 'flex',
                justifyContent: 'center',
                marginBottom: toRem(50)
            }),
            ...((variant === 'beta' || isColumnist) && {
                marginBottom: toRem(40),
                '& .HeadingLabel_inner::after': {
                    content: 'none'
                }
            }),
            [theme.breakpoints.down('sm')]: {
                marginBottom: toRem(12),
                ...(isColumnist && {
                    justifyContent: 'left'
                })
            }
        },
        '& .articleHead_subtitle': {
            color: theme.colors.subtitle.default,
            fontSize: toRem(16),
            lineHeight: 1.4,
            [theme.breakpoints.up('md')]: {
                fontSize: toRem(20)
            }
        },
        '& .articleHead_titleWrap': {
            display: 'flex',
            justifyContent: config?.sm?.titleWrap?.justifyContent,
            alignItems: 'center'
        },
        '& .articleHead_title': {
            margin: toRems([10, 'auto', 16]), // revise this after client's response
            color: theme.colors.text.default,
            ...(variant === 'beta' && {
                margin: toRems([10, 0])
            }),
            [theme.breakpoints.up('md')]: {
                margin: toRems([15, 'auto']),
                ...(variant === 'beta' && {
                    fontSize: toRem(52),
                    margin: toRems([10, 0])
                })
            }
        },
        '& .articleHead_overall': {
            marginLeft: toRem(20)
        },
        '& .articleHead_lead': {
            marginBottom: toRem(25),
            fontSize: toRem(20),
            fontWeight: theme.typography.fontWeight.extraLight,
            lineHeight: 1.6,
            p: {
                fontFamily: 'Switzer-Extralight',
                fontSize: 'inherit',
                lineHeight: 'inherit',
                fontWeight: 'inherit',
                color: theme.colors.text.default
            },
            [theme.breakpoints.up('md')]: {
                marginBottom: toRem(70),
                fontSize: toRem(32)
            }
        },
        '& .articleHero_wrapper': {
            // @TODO: connect to layout dimensions for head
            maxWidth: toRem(1215),
            margin: config?.md?.heroWrapper?.variant[variant]?.margin,
            [theme.breakpoints.up('md')]: {
                ...(variant === 'alpha' && {
                    padding: toRems([0, 25])
                }),
                ...(variant === 'beta' && {
                    maxWidth: '50%',
                    flex: 1,
                    flexBasis: toRem(imageWidth)
                })
            }
        },
        '& .articleHero': {
            position: 'relative',
            marginBottom: toRem(40),
            ...(ampImageFitCover && {
                '& .ampImage img': {
                    objectFit: 'cover'
                }
            }),
            [theme.breakpoints.up('md')]: {
                marginBottom: config?.md?.hero?.variant[variant]?.marginBottom,
                height: config?.md?.hero?.variant[variant]?.height
            }
        },
        '& .imageDecoration_triangle': {
            position: 'absolute',
            right: 0,
            bottom: 0
        },
        '& .imageDecoration_graphic': {
            position: 'absolute',
            right: toRem(15),
            bottom: toRem(-20),
            [theme.breakpoints.up('md')]: {
                right: toRem(-25),
                bottom: toRem(-25),
                ...(variant === 'beta' && {
                    right: toRem(15)
                })
            }
        }
    }
}

export default style
