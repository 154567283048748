import { useMemo } from 'react'
import { useRouter } from 'next/router'

import { UUID_REGEXP } from '../next-config/regexp'

const stringToCorrelatorNumber = string =>
    string
        ?.split('')
        .map(c => c.charCodeAt(0))
        .join('')

const entityRegex = new RegExp(UUID_REGEXP, 'gi')

const useCorrelatorString = inputId => {
    const router = useRouter()

    const entityId = router.query.slugs.join('/').match(entityRegex)[0]

    return useMemo(
        () => stringToCorrelatorNumber((inputId || entityId).replace(/-/gim, '').toLowerCase()),
        [inputId, entityId]
    )
}

export default useCorrelatorString
