import { useMemo } from 'react'

import useCorrelatorString from '../../hooks/useCorrelatorString'

const formatIpromAd = (ad: any) => {
    if (!ad) {
        return null
    }
    try {
        const codes = ad?.codes
            ?.replace(/[^ -~]/gm, '')
            ?.trim()
            ?.split('/') // "i/net_hr:video/554/1"
        if (!codes?.length) {
            return null
        }

        const sitePath = codes[1]?.split(':')
        let position
        let offset
        // @NOTE: transform to mid roll to provide user with option of unmuteing
        switch (ad.name?.toLowerCase()) {
            case 'preroll':
                position = 'pre'
                offset = 0
                break
            case 'midroll':
                position = 'mid'
                offset = 25
                break
            case 'endroll':
                position = 'end'
                offset = 75
                break
            case 'takeroll':
                position = 'mid'
                offset = 50
                break
            case 'postroll':
                position = 'post'
                offset = 100
                break
            default:
                position = 'mid'
                offset = 50
        }

        // @NOTE: ads parameters
        // sitePath ["m", "sid", "ssid"] array exp. ["RTL", "net_hr", "video"]
        // zones Zone ID array
        // time timestamp
        // adID integer
        // `https://core.iprom.net/vast?t=js&sitePath=${["m", "sid", "ssid"]}&zones=${[zoneID]}&count=2&vast=1&gaposition=pre&time=${timestamp}&vast_ver=2`

        // ${sitePath?.[0]}
        return {
            offset,
            url: `https://core.iprom.net/vast?sitePath=["RTL", "${sitePath?.[0]}"${
                sitePath?.[1] ? `, "${sitePath?.[1]}"` : ''
            }]&zones=[${codes[2]}]&count=${codes[3]}&time=[TIMESTAMP]`,
            position
            // type: 'linear'
        }
        // https://core.iprom.net/vast?sitePath=["demo","vast"]&zones=[146]&time=15261235126&time=[TIMESTAMP]&adID=652416
    } catch (error) {
        // eslint-disable-next-line no-console
        console.warn('Error while parsing video ad config', ad, error)
        return null
    }
}

// const consentStringSelector = state => state.user.consentString
export const useAdvertisingSupplementalData = () => {
    //
    const correlatorString = useCorrelatorString()
    return { correlatorString }
}

const useAdRequestQueryParams = (siteUrl: string, consentString: string) => {
    const correlatorString = useCorrelatorString()
    return useMemo(
        () =>
            `${siteUrl ? `&url=${siteUrl}` : ''}${correlatorString ? `&correlator=${correlatorString}` : ''}${
                consentString ? `&gdpr_consent=${consentString}` : ''
            }`,
        [correlatorString, siteUrl, consentString]
    )
}

export const useFormatedAds = adsInput =>
    useMemo(() => {
        const ads = Array.isArray(adsInput) ? adsInput : Object.values(adsInput)
        if (!ads?.length) {
            return []
        }

        const filteredAds = ads?.filter(ad => ad.codes)

        if (!filteredAds?.length) {
            return []
        }
        const formatedAds = filteredAds
            .map(formatIpromAd)
            .filter(Boolean)
            .sort((a, b) => a?.offset - b?.offset)
        return formatedAds as any[]
    }, [adsInput])

export const useVideoJsVastConfig = ({ ads, siteUrl, consentString }) => {
    const requestQueryParams = useAdRequestQueryParams(siteUrl, consentString)
    const formatedAds = useFormatedAds(ads)

    return useMemo(
        () => ({
            positions: formatedAds.map(ad => ({ ...ad, url: `${ad.url}${requestQueryParams}` })),
            companion: { elementId: 'VideoJs_vast_container' },
            hasAds: formatedAds?.length > 0
        }),
        [formatedAds, requestQueryParams]
    )
}
