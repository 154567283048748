// @TODO: Label component can have no lines variation instead doing it with css here
import { toRem, toRems } from '../../../../helpers/theme'

/* eslint-disable quote-props, no-dupe-keys, max-len */
const style = ({ theme, isHead }) => {
    const config = {
        sm: {
            padding: toRems([50, 0, 40, 0]),
            playerContainer: {
                height: 'auto'
            },
            fixed: {
                top: toRem(100),
                width: toRem(250),
                minWidth: toRem(200),
                height: toRem(150)
            }
        },
        md: {
            padding: toRems([50, 0, 40, 0]),
            playerContainer: {
                height: 'auto'
            },
            fixed: {
                top: toRem(100),
                width: toRem(400),
                minWidth: toRem(250),
                height: toRem(250)
            }
        }
    }
    return {
        position: 'relative',
        padding: !isHead ? config.sm.padding : 0,
        background: theme.colors.background.body.secondary,
        [theme.breakpoints.up('md')]: {
            padding: !isHead ? config.md.padding : 0
        },
        '& .videoContent_wrapper': {
            maxWidth: !isHead ? toRem(950) : '100%',
            margin: 'auto'
        },
        '& .videoContent_playerContainer': {
            position: 'relative',
            height: !isHead ? config.sm.playerContainer.height : toRem(300),
            [theme.breakpoints.up('md')]: {
                height: !isHead ? config.md.playerContainer.height : toRem(600)
            }
        },
        '& .videoContent_player': {
            width: !isHead ? toRem(900) : '100%',
            height: !isHead ? toRem(500) : toRem(600),
            [theme.breakpoints.up('md')]: {
                padding: toRems([0, 25])
            },
            transition: '0.6s'
        },
        '& .videoContent_player_fixed': {
            position: 'fixed',
            right: toRem(10),
            top: config.sm.fixed.top,
            zIndex: theme.zIndex.floatingVideo,
            width: config.sm.fixed.width,
            minWidth: config.sm.fixed.minWidth,
            height: config.sm.fixed.height,
            transition: '0.6s',
            [theme.breakpoints.up('md')]: {
                top: config.md.fixed.top,
                width: config.md.fixed.width,
                minWidth: config.md.fixed.minWidth,
                height: config.md.fixed.height
            }
        },
        '& .videoContent_close': {
            color: theme.colors.text.white,
            backgroundColor: theme.colors.text.accent,
            width: toRem(40),
            height: toRem(40),
            fontSize: toRem(20),
            textDecoration: 'none',
            position: 'absolute',
            zIndex: theme.zIndex.floatingVideo + 1,
            right: 0,
            top: toRem(-40),
            opacity: 0.7,
            '&:hover': {
                cursor: 'pointer',
                opacity: 0.9
            }
        },
        '& .videoContent_videoJsPlayer': {
            // position: 'absolute',
            // bottom: 0,
            // right: 0,
            // width: '100%',
            // video: {
            //     maxWidth: '100%'
            // },
            '.bmpui-ui-watermark': {
                display: 'none'
            },
            '.bmpui-ui-hugeplaybacktogglebutton .bmpui-image': {
                // backgroundImage: `url("/images/video/icon.svg")`,
                backgroundSize: toRem(80)
            },
            '.bmpui-ui-seekbar .bmpui-seekbar .bmpui-seekbar-playbackposition': {
                backgroundColor: theme.colors.zenaLightPink
            },
            '.bmpui-seekbar-playbackposition-marker': {
                backgroundColor: '#f8f9fa80',
                borderColor: theme.colors.zenaLightPink
            },
            '.bmpui-seekbar-playbackposition': {
                backgroundColor: theme.colors.zenaLightPink
            },
            '.bmpui-ui-uicontainer.bmpui-controls-shown .bmpui-ui-hugeplaybacktogglebutton:focus': {
                boxShadow: `inset -4px -3px 2px 9px ${theme.colors.zenaLightPink}`
            },
            '.bmpui-ui-recommendation-overlay': {
                backgroundColor: 'transparent'
            }
        },
        '& .videoContent_playerMarker': {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)'
        },
        '& .videoContent_head': {
            marginTop: toRem(30),
            padding: toRems([0, 25]),
            [theme.breakpoints.up('md')]: {
                marginTop: toRem(15)
            }
        },
        '& .videoContent_label': {
            margin: toRems([0, 0, 10, 0]),
            [theme.breakpoints.up('md')]: {
                display: 'inline-block',
                margin: toRems([0, 8, 0, 0]),
                '& .HeadingLabel_inner::before': {
                    content: 'none'
                }
            },
            '& .HeadingLabel_inner::after': {
                content: 'none'
            }
        },
        '& .videoContent_subtitle': {
            display: 'inline-block',
            color: theme.colors.subtitle.default,
            fontSize: toRem(16),
            lineHeight: 1.4
        },
        '& .videoContent_title': {
            margin: toRems([4, 0, 0, 0]),
            color: theme.colors.heading.basic.text.default,
            fontSize: toRem(20),
            [theme.breakpoints.up('md')]: {
                margin: toRems([0, 0, 0, 0]),
                fontSize: `${toRem(24)} !important`
            }
        },
        '& .videoContent_lead': {
            p: {
                color: theme.colors.text.default,
                lineHeight: 1.8,
                fontSize: `${toRem(16)} !important`,
                [theme.breakpoints.up('md')]: {
                    fontSize: `${toRem(18)} !important`
                }
            }
        },
        '& .videoContent_triangle': {
            position: 'absolute',
            right: 0,
            bottom: 0,
            [theme.breakpoints.down('md')]: {
                display: 'none'
            }
        },
        '& .videoContent_graphic': {
            position: 'absolute',
            right: toRem(15),
            bottom: toRem(-40),
            [theme.breakpoints.down('md')]: {
                display: 'none'
            }
        }
    }
}

export default style
