const getGiphyId = url => {
    if (!url) {
        return ''
    }
    let indexStart
    let indexEnd
    // ----- https://i.giphy.com/VmzI60RQG0fuw.gif
    indexStart = url.indexOf('i.giphy.com/')
    indexEnd = url.indexOf('.gif')
    if (indexStart !== -1) {
        indexStart += 12
        return url.substring(indexStart, indexEnd)
    }
    // ----- media.giphy.com/media/VmzI60RQG0fuw/giphy.gif
    indexStart = url.indexOf('media.giphy.com/media/')
    indexEnd = url.indexOf('/giphy.gif')
    if (indexStart !== -1) {
        indexStart += 22
        return url.substring(indexStart, indexEnd)
    }
    // ----- media3.giphy.com/media/taMR5k0HEfhFC/giphy.gif
    indexStart = url.indexOf('media3.giphy.com/media/')
    indexEnd = url.indexOf('/giphy.gif')
    if (indexStart !== -1) {
        indexStart += 22
        return url.substring(indexStart, indexEnd)
    }
    // ----- http://giphy.com/gifs/VmzI60RQG0fuw
    // ----- http://giphy.com/gifs/music-videos-mariah-carey-dreamlover-VmzI60RQG0fuw
    const tempUrl = url.replace('/html5', '')
    indexStart = tempUrl.indexOf('giphy.com/gifs/')
    indexEnd = tempUrl.lastIndexOf('-')
    if (indexStart !== -1) {
        if (indexEnd === -1) {
            indexStart += 15
            return tempUrl.substring(indexStart)
        }
        indexStart = indexEnd + 1
        return tempUrl.substring(indexStart)
    }
    // ----- http://giphy.com/embed/VmzI60RQG0fuw
    indexStart = url.indexOf('giphy.com/embed/')
    if (indexStart !== -1) {
        indexStart += 16
        return url.substring(indexStart)
    }
    return ''
}

export default getGiphyId
