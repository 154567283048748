import React, { useMemo } from 'react'
import { useUIDSeed } from 'react-uid'

import { useVideoAdList } from '../../context/ads/manifest.context'
import { useEmbedById } from '../../context/embeds'
import getGiphyId from '../../helpers/getGiphyId'
import { namespace as docbookNamespace, parseHtml as parseHtmlContent, useVideoThumbnail } from '../../hooks'
import { sanitizeHtmlContent } from '../../hooks/useInnerHtml'
import { RenderInView } from '../RenderInView'
import commonEmbeds from './HtmlContent.embeds'
import {
    Column,
    EmbedImage,
    EmbedPoll,
    H1,
    H2,
    H3,
    H4,
    H5,
    H6,
    Ol,
    Paragraph,
    Quote,
    Row,
    Table,
    Ul
} from './HtmlContent.style'
import { replaceChildren } from './replaceChildren'

const parseHtml = content => {
    let result = content
    try {
        result = parseHtmlContent(content)
    } catch (error) {
        //
    }
    return result
}

const elements = ({
    embeds: customEmbeds,
    ArticleBlockquote = () => 'Missing article Blockquote',
    ArticlePoll = () => 'Missing article Poll',
    ArticleImageDescription = () => null,
    VideoContent = () => 'Missing Video Content',
    blockquoteVariant
}) => {
    const embeds = new Map([...commonEmbeds, ...customEmbeds])
    const elementMap = new Map()

    elementMap.set('core/heading', ({ attributes }) => {
        // console.log('[HEADING]', block)
        const { content, level, ...attrs } = attributes
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const parsedContent = useMemo(
            () => replaceChildren(parseHtml(sanitizeHtmlContent(content)), elementMap),
            [content]
        )
        if (level === 1) {
            return <H1 {...attrs}>{parsedContent}</H1>
        }
        if (level === 2) {
            return <H2 {...attrs}>{parsedContent}</H2>
        }
        if (level === 3) {
            return <H3 {...attrs}>{parsedContent}</H3>
        }
        if (level === 4) {
            return <H4 {...attrs}>{parsedContent}</H4>
        }
        if (level === 5) {
            return <H5 {...attrs}>{parsedContent}</H5>
        }
        if (level === 6) {
            return <H6 {...attrs}>{parsedContent}</H6>
        }
        return null
    })

    elementMap.set('core/paragraph', ({ attributes }) => {
        // console.log('[PARAGRAPH]', block)
        const { content, ...attrs } = attributes
        // @NOTE: solves the problem for target blank but find a better way
        const contentWithTargetBlank = content?.replaceAll('<a ', '<a target="_blank" ')
        const parsedContent = useMemo(() => replaceChildren(parseHtml(contentWithTargetBlank), elementMap), [content])
        return <Paragraph {...attrs}>{parsedContent}</Paragraph>
    })
    elementMap.set('core/list', ({ attributes }) => {
        const { values, ordered, ...attrs } = attributes
        const parsedContent = useMemo(() => replaceChildren(parseHtml(values), elementMap), [values])
        if (ordered) {
            return <Ol {...attrs}>{parsedContent}</Ol>
        }
        return <Ul {...attrs}>{parsedContent}</Ul>
    })
    elementMap.set('core/columns', block => {
        const { innerBlocks } = block
        return <Row>{innerBlocks.map(blockItem => elementMap.get('core/column')(blockItem))}</Row>
    })
    elementMap.set('core/column', block => {
        // console.log('[COLUMN]', block)
        const { innerBlocks, clientId, attributes } = block
        const { width = '100%', ...rest } = attributes || {}
        return (
            <Column {...rest} setWidth={width} key={clientId}>
                {innerBlocks.map(blockItem => elementMap.get(blockItem.name)(blockItem))}
            </Column>
        )
    })
    elementMap.set('core/table', ({ attributes }) => {
        // console.log('[TABLE]', block)
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const uid = useUIDSeed()
        const { head, body, foot } = attributes
        return (
            <Table>
                <table>
                    {head?.map((row, index) => (
                        <tr key={uid(row?.id || index)}>
                            {row?.cells?.map((cell, cellIndex) => (
                                <td key={uid(cell?.id || cellIndex)}>{cell.content}</td>
                            ))}
                        </tr>
                    ))}
                    {body?.map((row, index) => (
                        <tr key={uid(row?.id || index)}>
                            {row?.cells?.map((cell, cellIndex) => (
                                <td key={uid(cell?.id || cellIndex)}>{cell.content}</td>
                            ))}
                        </tr>
                    ))}
                    {foot?.map((row, index) => (
                        <tr key={uid(row?.id || index)}>
                            {row?.cells?.map((cell, cellIndex) => (
                                <td key={uid(cell?.id || cellIndex)}>{cell.content}</td>
                            ))}
                        </tr>
                    ))}
                </table>
            </Table>
        )
    })
    elementMap.set('core/embed', ({ attributes }) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { providerNameSlug, namespace, previewable, ...attrs } = attributes
        const EmbedComponent = embeds.get(providerNameSlug)
        if (!EmbedComponent) {
            return null
        }
        return <EmbedComponent {...attrs} />
    })
    elementMap.set('core/quote', ({ attributes }) => {
        const { value, citation } = attributes
        const regex = /(<([^>]+)>)/gi
        const quoteText = value?.replace(regex, '')
        return (
            <Quote>
                <ArticleBlockquote content={quoteText} />
                <ArticleImageDescription description={citation} variant={blockquoteVariant} />
            </Quote>
        )
    })
    elementMap.set('core/image', ({ attributes }) => {
        // console.log('[CORE/IMAGE]', block)
        // @TODO: find better solution for giphy id
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { url, caption, linkDestination, width, height, alt, ...attrs } = attributes
        const isGiphy = url.indexOf('giphy') !== -1

        const renderGiphy = () => {
            if (isGiphy) {
                return (
                    <iframe title={url} src={`https://giphy.com/embed/${getGiphyId(url)}`} width="100%" height={400} />
                )
            }

            return (
                <>
                    {/*
                        @NOTE: using html img tag for now because sometimes image component throws 403

                        examples:

                        https://camo.githubusercontent.com/100995ad676f7299b746dbe16055485aee91114e14673c7b8dde9bb0c81dc3c4/68747470733a2f2f696d616765732e73667463646e2e6e65742f696d616765732f745f6170702d636f7665722d6c2c665f6175746f2f702f63653265636536302d396233322d313165362d393561622d3030313633656438333365372f3236303636333731302f7468652d746573742d66756e2d666f722d667269656e64732d73637265656e73686f742e6a7067

                        https://images.unsplash.com/photo-1444703686981-a3abbc4d4fe3?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80
                    */}
                    {/* <Image image={url?.replace(/^https?:/, '')} height={400} width={700} useLargestInstance /> */}

                    <img src={url} alt={alt || url} loading="lazy" />

                    <ArticleImageDescription description={caption} />
                </>
            )
        }

        return (
            <EmbedImage isGiphy={isGiphy} {...attrs} blockWidth={width} blockHeight={height}>
                {renderGiphy()}
            </EmbedImage>
        )
    })
    elementMap.set(`${docbookNamespace}/video`, ({ attributes }) => {
        const { id, caption } = attributes
        const videoElastic = useEmbedById(id)
        const thumbnail = useVideoThumbnail({ videoElastic })

        const videoAds = useVideoAdList()
        if (!videoElastic) {
            return null
        }
        return (
            <>
                <RenderInView>
                    <VideoContent
                        ads={videoAds}
                        id={id}
                        inViewAutoplay
                        isEmbed
                        thumbnail={thumbnail}
                        sticky
                        url={
                            videoElastic?.extended_attributes?.stream_url ||
                            videoElastic?.original_url ||
                            videoElastic?.custom_manifest_url
                        }
                    />
                </RenderInView>
                <ArticleImageDescription description={caption} />
            </>
        )
    })
    elementMap.set(`${docbookNamespace}/poll`, ({ attributes }) => {
        // console.log('[POLL]', block)
        const { id, ...attrs } = attributes
        return (
            <EmbedPoll {...attrs}>
                {id && (
                    <>
                        <div id="anketa" />
                        <ArticlePoll id={id} />
                    </>
                )}
            </EmbedPoll>
        )
    })
    elementMap.set(`${docbookNamespace}/brand`, () => null)
    return elementMap
}

export default elements
