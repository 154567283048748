import { toRem, toRems } from '../../../../helpers/theme'

/* eslint-disable quote-props, no-dupe-keys, max-len */
const style = ({ theme, variant }) => {
    const config = {
        xs: {
            graphic: {
                variant: {
                    alpha: {
                        top: 'initial',
                        left: toRem(40),
                        right: 'initial',
                        bottom: toRem(-16)
                    },
                    beta: {
                        top: 0,
                        left: 'initial',
                        right: toRem(-16),
                        bottom: 0
                    },
                    gamma: {
                        top: 'initial',
                        left: 0,
                        right: 0,
                        bottom: toRem(-16)
                    }
                }
            }
        },
        md: {
            graphic: {
                variant: {
                    alpha: {
                        top: 'initial',
                        left: toRem(50),
                        bottom: toRem(-29),
                        right: 'initial'
                    },
                    beta: {
                        top: 0,
                        left: 'initial',
                        right: toRem(-20),
                        bottom: 0
                    },
                    gamma: {
                        top: 'initial',
                        left: 'initial',
                        right: toRem(-22),
                        bottom: toRem(-22)
                    }
                }
            }
        }
    }

    return {
        position: 'relative',
        marginBottom: toRem(50),
        '& .articleBlockquote_wrapper': {
            position: 'relative',
            padding: toRems([23, 20, 26, 40]),
            background: theme.colors.article.blockquote.background,
            border: `1px solid ${theme.colors.article.blockquote.border}`,
            [theme.breakpoints.up('md')]: {
                padding: toRems([23, 40, 38, 50]),
                ...(['alpha', 'beta'].includes(variant) && {
                    maxWidth: toRem(550)
                })
            }
        },
        '& .articleBlockquote_inner': {
            marginTop: toRem(-34),
            [theme.breakpoints.up('md')]: {
                marginTop: toRem(-42)
            }
        },
        '& .articleBlockquote_graphic': {
            margin: 'auto',
            position: 'absolute',
            top: config?.xs.graphic.variant[variant]?.top,
            left: config?.xs.graphic.variant[variant]?.left,
            right: config?.xs.graphic.variant[variant]?.right,
            bottom: config?.xs.graphic.variant[variant]?.bottom,
            ...(variant === 'beta' && {
                height: '65%'
            }),
            [theme.breakpoints.up('md')]: {
                top: config?.md.graphic.variant[variant]?.top,
                left: config?.md.graphic.variant[variant]?.left,
                right: config?.md.graphic.variant[variant]?.right,
                bottom: config?.md.graphic.variant[variant]?.bottom
            },
            [theme.breakpoints.down('sm')]: {
                ...(variant === 'gamma' && {
                    width: `calc(100% - ${toRem(60)})`
                })
            }
        },
        '& .articleBlockquote_iconWrapper': {
            position: 'absolute',
            top: toRem(-20),
            left: toRem(-5),
            paddingRight: toRem(12),
            background: theme.colors.article.blockquote.background,
            color: theme.colors.text.accent,
            [theme.breakpoints.up('md')]: {
                top: toRem(-30),
                left: toRem(-10)
            }
        },
        '& .articleBlockquote_icon': {
            background: theme.colors.article.blockquote.background,
            color: theme.colors.text.accent,
            fontSize: toRem(34),
            [theme.breakpoints.up('md')]: {
                fontSize: toRem(48)
            }
        },
        svg: {
            path: {
                fill: 'currentColor'
            }
        }
    }
}

export default style
