import { useMemo } from 'react'
import { useAmp } from 'next/amp'
import PropTypes from 'prop-types'

import extractYoutubeVideoId from '../../../helpers/extractYoutubeVideoId'
import { AmpYoutube } from '../../Amp'

// This is a generic Youtube embed component, has no styling of it's own
function YoutubeEmbed({ url }) {
    const isAmp = useAmp()

    const videoId = useMemo(() => extractYoutubeVideoId(url) || '', [url])

    // @TODO: handle warning on preview, on production let youtube embed show the warning
    // if (!videoId) {
    //     return null
    // }

    return isAmp ? (
        <AmpYoutube width="480" height="270" layout="responsive" videoId={videoId} />
    ) : (
        <iframe style={{ border: '0' }} title={url} src={`https://www.youtube.com/embed/${videoId}`} loading="lazy" />
    )
}

YoutubeEmbed.propTypes = {
    url: PropTypes.string
}

YoutubeEmbed.defaultProps = {
    url: undefined
}
export default YoutubeEmbed
