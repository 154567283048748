// @TODO: Share dropdown (but maybe the whole component will be removed)

import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { Icon, ShareIcon } from '../../../Icon'
import styles from './Share.style'

const ArticleShareStyled = styled.div(props => ({ ...styles(props) }))

function ArticleShare({ shareUrl, shareText, className, ...rest }) {
    if (!shareUrl) {
        return null
    }

    return (
        <ArticleShareStyled className={className} {...rest}>
            <a href={shareUrl} className="articleShare_inner" title={shareText}>
                <Icon icon={ShareIcon} />
                <div className="articleShare_text">{shareText}</div>
            </a>
        </ArticleShareStyled>
    )
}

ArticleShare.propTypes = {
    className: PropTypes.string,
    shareText: PropTypes.string,
    shareUrl: PropTypes.string
}

ArticleShare.defaultProps = {
    className: undefined,
    shareText: 'podijeli',
    shareUrl: undefined
}

export default ArticleShare
