import React, { createContext, useContext } from 'react'

const initialState = {
    entities: {}
}

const EmbedsContext = createContext(initialState)

export function EmbedsProvider({ children, embeds }) {
    return <EmbedsContext.Provider value={embeds}>{children}</EmbedsContext.Provider>
}

export function useEmbeds() {
    return useContext(EmbedsContext)
}

export function useEmbedById(id) {
    const { entities } = useEmbeds() || {}

    return entities?.[id]
}
