import PropTypes from 'prop-types'

function AmpIframe({ src, placeholder, width, height, ...rest }) {
    return (
        <amp-iframe
            width={width}
            height={height}
            layout="responsive"
            sandbox="allow-scripts allow-same-origin"
            style={{ border: '0' }}
            src={src}
            {...rest}>
            {placeholder && <amp-img layout="fill" src={placeholder} placeholder="" />}
        </amp-iframe>
    )
}

AmpIframe.propTypes = {
    src: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    placeholder: PropTypes.string
}

AmpIframe.defaultProps = {
    src: undefined,
    width: 16,
    height: 9,
    placeholder: undefined
}
export default AmpIframe
