import { toRem, toRems } from '../../../../helpers/theme'

/* eslint-disable quote-props, no-dupe-keys, max-len */
const style = ({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    '& .articleMeta_inner': {
        marginRight: toRem(10),
        flexShrink: 0
    },
    '& .articleMeta_author': {
        fontSize: toRem(14),
        lineHeight: 1.4,
        fontWeight: theme.typography.fontWeight.bold,
        color: theme.colors.text.default,
        [theme.breakpoints.up('md')]: {
            fontSize: toRem(16)
        }
    },
    '& .articleMeta_date': {
        color: theme.colors.text.alternative,
        fontSize: toRem(16),
        [theme.breakpoints.down('sm')]: {
            lineHeight: 1.4,
            fontSize: toRem(14)
        }
    },
    '& .articleMeta_sourceWrap': {
        display: 'flex',
        fontSize: toRem(16),
        lineHeight: 1.4,
        margin: toRems([0, 5, 25, 0]),
        '& p': {
            fontSize: toRem(16),
            fontWeight: theme.typography.fontWeight.bold,
            height: '100%',
            marginBottom: 0
        }
    },
    '& .articleMeta_sourceTitle': {
        margin: toRems([3, 5, 0, 0])
    },
    '& .articleMeta_graphic': {
        width: '100%'
    }
})

export default style
