import React from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'
import { useGTM } from '@hmn/rtl-web-core/hooks'

import { Icon, ReadMoreIcon } from '../../../Icon'
import { Link } from '../../../Link'
import styles from './ReadMore.style'

const ReadMoreStyled = styled.div(props => ({ ...styles(props) }))

function ReadMore({ className, title, href, hrefAs, ...rest }) {
    const { sendDataToGTM } = useGTM()
    if (!title || !href) {
        return null
    }
    const gtmData = {
        eventCategory: 'Navigation',
        eventLabel: 'PrevNext',
        eventValue: 'ReadMore'
    }

    const handleClick = () => {
        sendDataToGTM(gtmData, true)
    }
    return (
        <ReadMoreStyled className={className} {...rest}>
            <Link href={href} hrefAs={hrefAs} onClick={handleClick}>
                {title}{' '}
                <Icon className="ReadMore_icon" icon={ReadMoreIcon} width="10" height="11" viewBox="0 0 10 11" />
            </Link>
        </ReadMoreStyled>
    )
}

ReadMore.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    href: PropTypes.string,
    hrefAs: PropTypes.string
}

ReadMore.defaultProps = {
    className: undefined,
    title: 'Ovdje pročitajte cijeli članak',
    href: undefined,
    hrefAs: undefined
}
export default withErrorBoundary(ReadMore, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[ArticleReadMore]: ', error, componentStack)
    }
})
