/* eslint-disable camelcase, max-len */
import styled from '@emotion/styled'
import { useAmp } from 'next/amp'
import PropTypes from 'prop-types'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'
import { useInnerHtml } from '@hmn/rtl-web-core/hooks'

import { ColumnistCard, columnistCardVariants } from '../../../Columnist/components'
import { Overall } from '../../../Comments/components'
import { Graphic, graphicVariants, Triangle } from '../../../Decoration/components'
import { HeadingLabel } from '../../../Heading/components/Label'
import { HtmlContent } from '../../../HtmlContent'
import { Image, imageRatioVariants } from '../../../Image'
import styles from './Head.style'

const HeadStyled = styled.section(props => ({ ...styles(props) }))

function Head({
    className,
    label,
    labelLink,
    labelTitle,
    subtitle,
    title,
    titleTagComponent: TitleTag,
    lead,
    image,
    imageWidth,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    imageHeight,
    imageVariation,
    alt,
    variant,
    halfGreyBackground,
    rate,
    maxRate,
    ampImageFitCover,
    isColumnist,
    columnist,
    ...rest
}) {
    const isAmp = useAmp()
    const labelHtmlProps = useInnerHtml(label)
    const subtitleHtmlProps = useInnerHtml(subtitle)
    const titleHtmlProps = useInnerHtml(title)

    return (
        <HeadStyled
            className={className}
            imageWidth={imageWidth}
            variant={variant}
            halfGreyBackground={halfGreyBackground}
            ampImageFitCover={ampImageFitCover}
            isColumnist={!!isColumnist}
            {...rest}>
            <div className="articleHead">
                {label && (
                    <HeadingLabel href={labelLink} hrefAs={labelLink} title={labelTitle} className="articleHead_label">
                        <span {...labelHtmlProps} />
                    </HeadingLabel>
                )}
                {subtitle && <div className="articleHead_subtitle" {...subtitleHtmlProps} />}
                {title && (
                    <div className="articleHead_titleWrap">
                        <TitleTag className="articleHead_title" {...titleHtmlProps} />
                        {rate && maxRate && (
                            <Overall className="articleHead_overall" size={50} max={maxRate} value={rate} />
                        )}
                    </div>
                )}
                {isColumnist && (
                    <ColumnistCard
                        className="articleHead_columnist"
                        columnist={columnist}
                        variant={columnistCardVariants.GAMMA}
                    />
                )}
                {lead && (
                    <div className="articleHead_lead">
                        <HtmlContent data={lead} />
                    </div>
                )}
            </div>
            {image && (
                <div className="articleHero_wrapper">
                    <div className="articleHero">
                        <Image
                            image={image}
                            variation={imageVariation || imageRatioVariants.CUSTOM_LANDSCAPE}
                            width={1200}
                            height={isAmp ? 1200 : 800}
                            alt={alt || title}
                            fullHeight
                            preload
                            lazyLoad={false}
                        />
                        <Triangle className="imageDecoration_triangle" />
                        <Graphic className="imageDecoration_graphic" variant={graphicVariants.ALPHA} />
                    </div>
                </div>
            )}
        </HeadStyled>
    )
}

const articleHeadVariants = Object.freeze({
    ALPHA: 'alpha',
    BETA: 'beta'
})

Head.propTypes = {
    className: PropTypes.string,
    label: PropTypes.string,
    labelLink: PropTypes.string,
    labelTitle: PropTypes.string,
    subtitle: PropTypes.string,
    title: PropTypes.string,
    titleTagComponent: PropTypes.elementType,
    lead: PropTypes.string,
    image: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    imageWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    imageHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    imageVariation: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    alt: PropTypes.string,
    variant: PropTypes.oneOf([...Object.values(articleHeadVariants)]),
    halfGreyBackground: PropTypes.bool,
    rate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    maxRate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    ampImageFitCover: PropTypes.bool,
    isColumnist: PropTypes.bool,
    columnist: PropTypes.shape({})
}

Head.defaultProps = {
    className: undefined,
    label: undefined,
    labelLink: undefined,
    labelTitle: undefined,
    subtitle: undefined,
    title: undefined,
    titleTagComponent: 'h1',
    lead: undefined,
    image: undefined,
    imageWidth: undefined,
    imageHeight: undefined,
    imageVariation: undefined,
    alt: undefined,
    variant: articleHeadVariants.ALPHA,
    halfGreyBackground: false,
    rate: undefined,
    maxRate: undefined,
    ampImageFitCover: undefined,
    isColumnist: false,
    columnist: undefined
}

export { articleHeadVariants }

export default withErrorBoundary(Head, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[ArticleHead]: ', error, componentStack)
    }
})
