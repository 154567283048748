import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'

import { Graphic, graphicVariants } from '../../../Decoration/components'
import { Icon, QuoteIcon } from '../../../Icon'
import styles from './Blockquote.style'
import BlockquoteText from './Text/Text.component'

const HeadingBlockquoteStyled = styled.div(props => ({ ...styles(props) }))

function Blockquote({ quoteText, variant, className, ...rest }) {
    if (!quoteText) {
        return null
    }

    return (
        <HeadingBlockquoteStyled className={className} variant={variant} {...rest}>
            <Graphic
                className="articleBlockquote_graphic"
                variant={variant === 'beta' ? graphicVariants.DELTA : graphicVariants.GAMMA}
            />
            <div className="articleBlockquote_wrapper">
                <div className="articleBlockquote_inner">
                    <BlockquoteText className={className}>{quoteText}</BlockquoteText>
                </div>
                <div className="articleBlockquote_iconWrapper">
                    <Icon className="articleBlockquote_icon" icon={QuoteIcon} viewBox="0 0 48 48" />
                </div>
            </div>
        </HeadingBlockquoteStyled>
    )
}

const articleBlockquoteVariants = Object.freeze({
    ALPHA: 'alpha',
    BETA: 'beta',
    GAMMA: 'gamma'
})

Blockquote.propTypes = {
    className: PropTypes.string,
    quoteText: PropTypes.string,
    variant: PropTypes.oneOf([...Object.values(articleBlockquoteVariants)])
}

Blockquote.defaultProps = {
    className: undefined,
    quoteText: undefined,
    variant: articleBlockquoteVariants.ALPHA
}

export { articleBlockquoteVariants }

export default withErrorBoundary(Blockquote, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[ArticleBlockquote]: ', error, componentStack)
    }
})
