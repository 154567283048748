/* eslint-disable @typescript-eslint/naming-convention */
import clsx from 'clsx'
import Script from 'next/script'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { VideoJsActions } from '../../actions/index'
import useLocalStorage from '../../hooks/useLocalStorage'
import RtlVideoJs from './RtlVideoJs/RtlVideoJs'
import { useDidomiConsentState } from './useDidomiConsentState'
// eslint-disable-next-line import/extensions
import { useVideoJsVastConfig } from './VideoJsPlayer.ads'
import { useVideoJsGTM } from './VideoJsPlayer.gtm'
import { usePlayerOptions } from './VideoJsPlayer.hooks'
import VideoJsPlayerStyled from './VideoJsPlayer.style'

const videoJsSelector = state => state.videoJs

export const VideoJS = ({
    className,
    title,
    ads,
    autoplay,
    inViewAutoplay,
    isEmbed,
    description,
    url,
    thumbnail,
    siteUrl,
    sticky,
    fill,
    fluid
}) => {
    const { initiated, changed, consent, timedOut } = useDidomiConsentState()
    const playerRef = React.useRef<RtlVideoJs | null>(null)
    const [userMuted, setUserMuted] = useLocalStorage('videojs_startMuted', true)

    useEffect(() => {
        if (playerRef.current) {
            if (autoplay && !playerRef.current.scheduler.hasPlayed) {
                playerRef.current.player.muted(true)
                return
            }
            if (playerRef.current.player.muted() !== userMuted) playerRef.current.player.muted(userMuted)
        }
    }, [userMuted, autoplay])
    const videoContainerRef = React.useRef<HTMLDivElement | null>(null)
    const vastConfig = useVideoJsVastConfig({
        ads,
        siteUrl,
        consentString: (changed && consent?.consentString) || undefined
    })
    const enabled = (initiated && changed) || timedOut
    const options = usePlayerOptions({
        title,
        description,
        url,
        thumbnail,
        autoplay,
        inViewAutoplay,
        sticky,
        fill,
        fluid,
        muted: true
    })
    const dispatch = useDispatch()

    const setImaLoaded = useCallback(() => {
        dispatch(VideoJsActions.imaLoaded())
    }, [])

    const setImaLoadingFailed = useCallback(() => {
        dispatch(VideoJsActions.imaLoadingFailed())
    }, [])

    const { imaLoaded, imaLoadingFailed } = useSelector(videoJsSelector)
    // console.log({ imaLoaded, imaLoadingFailed })
    const gtm = useVideoJsGTM({
        isEmbed,
        url
    })
    useEffect(() => {
        const hasImaLoaded = imaLoaded === true && imaLoadingFailed === false
        const hasImaLoadingFailed = imaLoadingFailed === true
        const videoPlayerReady = hasImaLoaded || hasImaLoadingFailed
        if (
            !enabled ||
            !videoContainerRef.current ||
            !options.sources.filter(Boolean).length ||
            (vastConfig && vastConfig.hasAds && hasImaLoaded && !window?.google?.ima?.VERSION)
        ) {
            return
        }

        if (playerRef.current) {
            const player = playerRef.current
            player.src(options.sources)
            return
        }

        if (!videoPlayerReady) {
            return
        }
        videoContainerRef.current.innerHTML = ''
        playerRef.current = new RtlVideoJs({
            options,
            source: options.sources,
            parentContainer: videoContainerRef.current,
            vast: !hasImaLoadingFailed ? vastConfig : undefined,
            on: {
                play: rtlPlayer => {
                    gtm.onPlay()
                    const currentPosition = rtlPlayer.scheduler.getPlayFraction() * 100
                    if (Math.floor(currentPosition)) {
                        gtm.onPlay({ eventAction: Math.floor(currentPosition) })
                    }
                },
                pause: rtlPlayer => {
                    gtm.onPause()
                    const currentPosition = rtlPlayer.scheduler.getPlayFraction() * 100
                    gtm.onPause({ eventAction: Math.floor(currentPosition) })
                },
                ended: () => {
                    gtm.onComplete()
                    gtm.onComplete({ eventAction: 100 })
                },
                mute: () => {
                    setUserMuted(true)
                },
                unmute: () => {
                    setUserMuted(false)
                }
            },
            onIma: {
                start: rtlPlayer => {
                    try {
                        const currentAd = rtlPlayer.getCurrentAd()
                        if (!currentAd) {
                            return
                        }
                        const eventValue = parseInt(currentAd.offset || '0', 10)
                        const eventAction = `${currentAd.position}-roll-${currentAd.playCount ?? 1}`
                        if (eventAction) {
                            gtm.onAd({ eventAction })
                        }
                        if (eventValue) {
                            gtm.onAd({ eventAction: eventValue })
                        }
                    } catch (e) {
                        // no need to log
                    }
                }
            }
        })
    }, [options, vastConfig, gtm, imaLoaded, imaLoadingFailed, enabled, setUserMuted])

    return (
        <VideoJsPlayerStyled data-vjs-player className={clsx('VideoJs_player', className)}>
            <div ref={videoContainerRef} className="VideoJs_player_container" data-upscore-video-type="videojs">
                {(thumbnail && <img alt={title || 'thumbnail'} src={thumbnail} />) || null}
            </div>
            <Script
                id="google-ima-sdk"
                key="google-ima-sdk"
                src="https://imasdk.googleapis.com/js/sdkloader/ima3.js"
                onLoad={setImaLoaded}
                onError={e => {
                    // eslint-disable-next-line no-console
                    console.error('ima3 script failed to load', e)
                    setImaLoadingFailed()
                }}
            />
        </VideoJsPlayerStyled>
    )
}

const videoAdPropType = PropTypes.shape({ name: PropTypes.string, codes: PropTypes.string })
export const videoAdsPropType = PropTypes.shape({
    preroll: videoAdPropType,
    midroll: videoAdPropType,
    postroll: videoAdPropType,
    endroll: videoAdPropType,
    takeroll: videoAdPropType
})

VideoJS.propTypes = {
    ads: videoAdsPropType,
    className: PropTypes.string,
    description: PropTypes.string,
    isEmbed: PropTypes.bool,
    thumbnail: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    title: PropTypes.string,
    url: PropTypes.string,
    containerRef: PropTypes.shape({}),
    siteUrl: PropTypes.string,
    sticky: PropTypes.bool,
    fill: PropTypes.bool,
    fluid: PropTypes.bool,
    autoplay: PropTypes.bool,
    inViewAutoplay: PropTypes.bool
}
VideoJS.defaultProps = {
    ads: undefined,
    className: undefined,
    description: undefined,
    isEmbed: false,
    thumbnail: undefined,
    title: undefined,
    url: undefined,
    containerRef: {},
    siteUrl: 'rtl.hr',
    sticky: true,
    fill: false,
    fluid: true,
    autoplay: false,
    inViewAutoplay: false
}
export default VideoJS
