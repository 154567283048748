import videojs from 'video.js'

import { RtlVideoJsOptions } from './RtlVideoJs'

declare global {
    interface Window {
        players: Map<VideoJsScheduler, undefined>
    }
}
// @todo: refactor this to WeakMap with stored ids
// needs refactor to promise based
if (typeof window !== 'undefined') {
    window.players = window.players ?? new Map<VideoJsScheduler, undefined>()
}

const checkIfAnotherPlayerIsPlaying = (currentPlayer: VideoJsScheduler) => {
    let isPlaying = false
    const results: boolean[] = []
    window.players.forEach((_, p) => {
        if (p === currentPlayer) return
        results.push(p.isPaused())
        if (!p.isPaused()) {
            isPlaying = true
        }
    })
    return isPlaying
}

export const RTL_VIDEOJS_DEACTIVATE_EVENT = 'rtl-videojs-deactivate'

const pauseAllOtherPlayers = (player: VideoJsScheduler) => {
    // console.log('VideoJsScheduler: pauseAllOtherPlayers', players)
    window.players.forEach((_, p) => {
        if (p === player) return
        try {
            p.player.trigger(RTL_VIDEOJS_DEACTIVATE_EVENT)
            if (!p.isPaused()) {
                p.pause()
            }
        } catch (e) {
            console.error(new Error('videojs scheduler pause error'), e)
        }
    })
}

type SchedulerOptions = Pick<RtlVideoJsOptions, 'autoplay' | 'muted'>

class VideoJsScheduler {
    player: videojs.Player
    options: SchedulerOptions
    hasPlayed = false
    constructor(player: videojs.Player, options?: SchedulerOptions) {
        if (!player) {
            throw new Error('VideoJsScheduler: player is undefined')
        }
        this.options = options || { autoplay: false, muted: true }
        this.player = player
        window.players.set(this, undefined)
        // console.log('VideoJsScheduler: players', players)
        player.on('dispose', () => {
            window.players.delete(this)
        })
        player.on(['readyforpreroll', 'readyforpostroll'], () => {
            this.play()
        })
        let forcePause = !options?.autoplay
        player.on('play', () => {
            if (this.checkIfAnotherPlayerIsPlaying() && !forcePause) {
                forcePause = true
                this.pause()
                return
            }
            pauseAllOtherPlayers(this)
            this.hasPlayed = true
        })
        this.attachTimedCallbacks()
    }
    async playMuted() {
        const handleError = (e: Error | unknown) => {
            console.error(new Error('videojs scheduler play error'), e)
            if (!this.options.muted) this.player?.muted(false)
        }
        this.player?.muted(true)
        try {
            await this.player.play()
        } catch (e) {
            handleError(e)
        }
    }
    checkIfAnotherPlayerIsPlaying() {
        return checkIfAnotherPlayerIsPlaying(this)
    }
    isWaitingToPlay = false
    play() {
        if (this.isWaitingToPlay) return
        this.isWaitingToPlay = true
        this.player.ready(() => {
            this.privatePlay()
            this.isWaitingToPlay = false
        })
    }

    private async privatePlay() {
        try {
            await this.player.play()
        } catch (e) {
            this.playMuted()
        }
    }

    pause() {
        this.player?.pause()
        try {
            // @ts-expect-error: videojs-ima is not typed
            this.player?.ima?.controller.sdkImpl.pauseAds()
        } catch (e) {
            // no handling needed, if there was an error, there was no ad to pause to begin with
        }
    }

    reset() {
        this.player?.currentTime(0)
    }

    seek(time: number) {
        this.player?.currentTime(time)
    }

    getPlayFraction() {
        if (!this.player) return 0
        if (!this.player.currentTime() || !this.player.duration()) return 0
        return this.player.currentTime() / this.player.duration()
    }
    onPercentageCallbacks: Map<number, (() => void)[]> = new Map()
    on(percentage: number, callback: () => void, once = true) {
        const callbacks = this.onPercentageCallbacks.get(percentage) || []
        const wrappedCallback = () => {
            if (once) {
                this.off(wrappedCallback)
            }
            // console.log('calling callback', percentage, once)
            return callback()
        }
        callbacks.push(wrappedCallback)
        // console.log('VideoJsScheduler: on', percentage, wrappedCallback, callbacks)
        this.onPercentageCallbacks.set(percentage, callbacks)
    }

    off(callback: () => void) {
        this.onPercentageCallbacks.forEach((callbacks, percentage) => {
            const index = callbacks.indexOf(callback)
            if (index > -1) {
                callbacks.splice(index, 1)
            }
            if (callbacks.length === 0) {
                this.onPercentageCallbacks.delete(percentage)
            }
        })
    }

    getPlayPercentage = () => {
        const fraction = this.getPlayFraction()
        return fraction ? Math.floor(fraction * 100) : 0
    }
    lastPercentage = 0
    runPlayPercentageCallbacks = true
    blockPlayPercentageCallbacks() {
        // console.log('VideoJsScheduler: blockPlayPercentageCallbacks')
        this.runPlayPercentageCallbacks = false
    }
    unblockPlayPercentageCallbacks() {
        // console.log('VideoJsScheduler: unblockPlayPercentageCallbacks')
        this.runPlayPercentageCallbacks = true
    }
    attachTimedCallbacks() {
        this.player.on('timeupdate', () => {
            const percentage = this.getPlayPercentage()
            if (!this.runPlayPercentageCallbacks) return
            const callbacks: (() => void)[] = []
            const diff = Math.max(0, percentage - this.lastPercentage)
            // console.log('VideoJsScheduler: attachTimedCallbacks', percentage, this.lastPercentage, diff)
            for (let i = 0; i < Math.abs(diff); i++) {
                const p = this.lastPercentage + diff + i
                const c = this.onPercentageCallbacks.get(p)
                if (c) {
                    callbacks.push(...c)
                }
            }
            this.lastPercentage = percentage

            if (callbacks) {
                callbacks.forEach(c => c())
            }
        })
    }

    isPaused() {
        const playerPaused = !!this.player?.paused()
        // console.log('player', this.player?.paused(), {
        //     // @ts-expect-error: videojs-ima is not typed
        //     ads: this.player.ads?.isAdPlaying(),
        //     // @ts-expect-error: videojs-ima is not typed
        //     imaAdPlaying: this.player.ima.controller.sdkImpl.adPlaying
        // })
        if (!playerPaused) return false
        let vastPaused = true
        try {
            // @ts-expect-error: videojs-ima is not typed
            vastPaused = this.player.ads?.isAdPlaying() ? !this.player.ima.controller.sdkImpl.adPlaying : true
        } catch (err) {
            // no need to log error here
        }
        return vastPaused
    }
}

export default VideoJsScheduler
