import React, { memo, useEffect, useMemo } from 'react'
import { PropTypes } from 'prop-types'

import { withErrorBoundary } from '../../components/ErrorBoundary/ErrorBoundary.component'

function RedditPostController({ url, height }) {
    const { subreddit, postId } = useMemo(() => {
        try {
            const urlObj = new URL(url)
            const pathParts = urlObj.pathname.split('/')
            if (pathParts.length < 5) {
                throw new Error('Invalid Reddit URL')
            }
            const subredditPart = pathParts[2]
            const postIdPart = pathParts[4]
            return { subreddit: subredditPart, postId: postIdPart }
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error('Failed to parse URL:', error)
            return { subreddit: null, postId: null }
        }
    }, [url])

    useEffect(() => {
        // Ensure the Reddit embed script is loaded
        const script = document.createElement('script')
        script.src = `https://embed.reddit.com/widgets.js`
        script.async = true
        script.charset = 'UTF-8'
        document.body.appendChild(script)

        // Clean up the script when the component unmounts
        return () => {
            document.body.removeChild(script)
        }
    }, [url])

    if (!subreddit || !postId) {
        return <div>Invalid Reddit URL</div>
    }

    return (
        <>
            <blockquote className="reddit-embed-bq" style={{ height }} data-embed-height="739">
                <a href={url}>Link to Reddit Post</a>
            </blockquote>
            {/* <Script src="https://embed.reddit.com/widgets.js" async /> */}
        </>
    )
}

RedditPostController.propTypes = {
    url: PropTypes.string,
    height: PropTypes.string
}

RedditPostController.defaultProps = {
    url: undefined,
    height: undefined
}

export default withErrorBoundary(memo(RedditPostController), {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[RedditPostController]: ', error, componentStack)
    }
})
