/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/prop-types */

// eslint-disable-next-line import/extensions
import commonElements from '@hmn/rtl-web-core/components/HtmlContent/HtmlContent.elements'
import { EmbedArticle, EmbedImage } from '@hmn/rtl-web-core/components/HtmlContent/HtmlContent.style'
import { Media } from '@hmn/rtl-web-core/components/Media'
import { useEmbedById } from '@hmn/rtl-web-core/context/embeds'
import getGiphyId from '@hmn/rtl-web-core/helpers/getGiphyId'
import { namespace as docbookNamespace } from '@hmn/rtl-web-core/hooks/getPagedBlocks'

import ArticleBlockquote from '../Article/components/Blockquote/Blockquote.component'
import ArticleCard, {
    articleCardContentMarkerVariants,
    articleCardSizeVariants,
    articleCardVariants
} from '../Article/components/Card/Card.component'
import ArticlePoll from '../Article/components/Poll/Poll.component'
import { ConditionalWrapper } from '../ConditionalWrapper'
import { Image, imageRatioVariants } from '../Image'
import { VideoContent } from '../Video/components'
import { embeds } from './HtmlContent.embeds'

// console.log(ArticlePoll)
// console.log(articleBlockquoteVariants)
const elements = commonElements({
    ArticleCard,
    ArticleBlockquote,
    articleCardContentMarkerVariants,
    articleCardSizeVariants,
    articleCardVariants,
    ArticlePoll,
    blockquoteVariant: 'gamma',
    // blockquoteVariant: articleBlockquoteVariants.GAMMA, // @NOTE: for some reason articleBlockquoteVariants is undefined
    embeds,
    imageRatioVariants,
    VideoContent
})
elements.set('core/image', ({ attributes }) => {
    // console.log('[CORE/IMAGE]', block)
    // @TODO: find better solution for giphy id
    const { url, width, height } = attributes
    const isGiphy = url?.indexOf('giphy') !== -1

    const renderGiphy = () => {
        if (isGiphy) {
            return <iframe title={url} src={`https://giphy.com/embed/${getGiphyId(url)}`} width="100%" height={400} />
        }

        return <Image variation={imageRatioVariants.CUSTOM_ORIGINAL} image={url} width={300} useLargestInstance />
    }

    return (
        <EmbedImage isGiphy={isGiphy} blockWidth={width} blockHeight={height}>
            {renderGiphy()}
        </EmbedImage>
    )
})
elements.set(`${docbookNamespace}/article`, ({ attributes }) => {
    const { namespace, entity, id, align, width, height, ...attrs } = attributes

    const article = useEmbedById(id)

    if (!article) {
        return null
    }

    return (
        <EmbedArticle {...attrs} align={align} blockWidth={width} blockHeight={height}>
            {article ? (
                <ArticleCard variant={articleCardVariants.THETA} item={article} href={article.sitemap?.href} embedded />
            ) : null}
        </EmbedArticle>
    )
})
elements.set(`${docbookNamespace}/recipe`, ({ attributes }) => {
    const { namespace, entity, id, align, width, height, ...attrs } = attributes

    const recipe = useEmbedById(id)

    return (
        <EmbedArticle {...attrs} align={align} blockWidth={width} blockHeight={height}>
            {recipe ? (
                <>
                    <Media greaterThan="sm" scaleDown>
                        <ArticleCard
                            variant={articleCardVariants.EPSILON}
                            rightAlign
                            item={recipe}
                            href={recipe.sitemap?.href}
                            imageVariation={imageRatioVariants.CUSTOM_LANDSCAPE}
                            embedded
                        />
                    </Media>
                    <Media lessThan="md" scaleDown>
                        <ArticleCard
                            variant={articleCardVariants.GAMMA}
                            rightAlign
                            item={recipe}
                            href={recipe.sitemap?.href}
                            imageVariation={imageRatioVariants.CUSTOM_LANDSCAPE}
                            embedded
                        />
                    </Media>
                </>
            ) : null}
        </EmbedArticle>
    )
})
elements.set(`${docbookNamespace}/product`, ({ attributes }) => {
    const { namespace, entity, id, width, align, height, ...attrs } = attributes

    const product = useEmbedById(id)

    return (
        <EmbedArticle {...attrs} align={align} blockWidth={width} blockHeight={height}>
            {product ? (
                <>
                    <Media greaterThan="sm" scaleDown>
                        <ArticleCard
                            variant={articleCardVariants.EPSILON}
                            rightAlign
                            item={product}
                            href={product.sitemap?.href}
                            imageVariation={imageRatioVariants.CUSTOM_LANDSCAPE}
                            embedded
                        />
                    </Media>
                    <Media lessThan="md" scaleDown>
                        <ArticleCard
                            variant={articleCardVariants.GAMMA}
                            rightAlign
                            item={product}
                            href={product.sitemap?.href}
                            imageVariation={imageRatioVariants.CUSTOM_LANDSCAPE}
                            embedded
                        />
                    </Media>
                </>
            ) : null}
        </EmbedArticle>
    )
})
elements.set(`${docbookNamespace}/gallery`, ({ attributes }) => {
    const { namespace, entity, id, align, column, width, height, ...attrs } = attributes

    const gallery = useEmbedById(id)

    return (
        <EmbedArticle {...attrs} align={align} blockWidth={width} blockHeight={height}>
            {gallery ? (
                <ArticleCard
                    item={gallery}
                    href={gallery.sitemap?.href}
                    inverted
                    rightAlign
                    variant={articleCardVariants.ALPHA}
                    contentMarkerVariant={articleCardContentMarkerVariants.PHOTO}
                    imageVariation={imageRatioVariants.CUSTOM_LANDSCAPE}
                />
            ) : null}
        </EmbedArticle>
    )
})
elements.set(`${docbookNamespace}/image`, ({ attributes }) => {
    const { namespace, entity, id, width, height, href, ...attrs } = attributes
    const target = !href?.startsWith(process.env.NEXT_PUBLIC_APP_ROOT_ZENA) ? '_blank' : null

    const image = useEmbedById(id)

    if (!id) {
        return null
    }
    return (
        <EmbedImage {...attrs} aspectRatio={image?.original_aspect_ratio}>
            <ConditionalWrapper condition={!!href} Wrapper="a" href={href} target={target}>
                <Image
                    variation={imageRatioVariants.CUSTOM_ORIGINAL}
                    classNameProgressive="progressive"
                    useLargestInstance
                    image={image}
                    width={width || 300}
                    height={height || 200}
                />
            </ConditionalWrapper>
        </EmbedImage>
    )
})
export { elements }
