import { useRef } from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import styles from './Text.style'

const BlockquoteTextStyled = styled.blockquote(props => ({ ...styles(props) }))

function BlockquoteText({ children, className, ...rest }) {
    const currentElement = useRef(null)

    if (typeof children !== 'string') {
        throw new Error(`Text component expected 'children' prop to be of string type.`)
    }

    if (!children?.length) {
        return null
    }

    return (
        <BlockquoteTextStyled ref={currentElement} className={className} {...rest}>
            <span>{children}</span>
        </BlockquoteTextStyled>
    )
}

BlockquoteText.propTypes = {
    className: PropTypes.string
}

BlockquoteText.defaultProps = {
    className: undefined
}

export default BlockquoteText
