import React from 'react'

export const replaceChildren = (children, elements) =>
    React.Children.map(children, item => {
        if (!React.isValidElement(item)) {
            return item
        }

        if (item.type?.startsWith('hmn:')) {
            return React.createElement('span', {}, replaceChildren(item.props.children, elements))
        }

        return React.cloneElement(item, item.props, replaceChildren(item.props.children, elements))
    })
