import { useMemo } from 'react'
import dayjs from 'dayjs'
import { NextSeo } from 'next-seo'
import PropTypes from 'prop-types'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'
import safelyJoinSlugs from '@hmn/rtl-web-core/helpers/safelyJoinSlugs'
import parseSeoDescription from '@hmn/rtl-web-core/helpers/seo/js/parseSeoDescription'
import { processOpenGraphImages } from '@hmn/rtl-web-core/helpers/seo/js/processOpenGraphImages'
import { useBreakpoints, useSeoImages } from '@hmn/rtl-web-core/hooks'

import { dateToSeo, organizationStructuredData, sitemapToBreadcrumbs, StructuredData } from '../../../Seo'

const generateVideoStructuredData = (
    item,
    imageUrl = `${process.env.NEXT_PUBLIC_APP_ROOT_ZENA || ''}/images/share/default.jpg`
) => {
    const title = item?.name
    const description = parseSeoDescription(item?.description || item?.lead) || 'Žena.hr video'
    const duration = item?.duration ? dayjs.duration({ seconds: item.duration }).toISOString() : undefined
    const publishDate = dateToSeo(item?.created_at || item?.updated_at)
    const updateDate = dateToSeo(item?.updated_at)
    const author = 'Žena.hr' || item?.author || item?.source || item?.extended_attributes?.author || 'Žena.hr'
    const itemHref =
        item?.sitemap?.href || (item?.path && `/${safelyJoinSlugs(item?.path?.map(p => p.slug))}`) || item?.href || ''
    return {
        name: title,
        image: imageUrl,
        '@type': 'VideoObject',
        description,
        inLanguage: 'Croatian',
        thumbnailUrl: imageUrl || `${process.env.NEXT_PUBLIC_APP_ROOT_ZENA}/images/share/default.jpg`,
        uploadDate: publishDate,
        dateModified: updateDate,
        duration,
        author,
        publisher: organizationStructuredData,
        contentUrl: item?.original_url || item?.custom_manifest_url,
        embedUrl: `${process.env.NEXT_PUBLIC_APP_ROOT_ZENA}${itemHref}`
    }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function Seo({ item, images, hasAmp, shareUrl, structuredDataOverwrite }) {
    // @TODO: merge images once they're available directly through item
    const [deviceType] = useBreakpoints(['xs', 'sm', 'md'], ['mobile', 'mobile', 'desktop'], null)
    const { imageMeta } = useSeoImages(images, {
        url: `${process.env.NEXT_PUBLIC_APP_ROOT_ZENA || ''}/images/share/default.jpg`,
        width: 1200,
        height: 630
    })

    const { meta, structuredData } = useMemo(() => {
        if (!item?.id) {
            return {}
        }
        const {
            google_title: googleTitle,
            mobile_title: mobileTitle,
            social_title: socialTitleInput
        } = item?.extended_attributes || {}

        const title = item?.name
        const deviceTitle = (deviceType === 'mobile' && mobileTitle) || title
        const description = parseSeoDescription(item?.description || item?.lead)
        // @TODO: load author/source properly, for now override with zena.hr
        const author = 'Žena.hr' || item?.author || item?.source || item?.extended_attributes?.author || 'Žena.hr'
        const updateDate = dateToSeo(item?.updated_at)
        const itemHref =
            item?.sitemap?.href ||
            (item?.path && `/${safelyJoinSlugs(item?.path?.map(p => p.slug))}`) ||
            item?.href ||
            ''
        const url = shareUrl || `${process.env.NEXT_PUBLIC_APP_ROOT_ZENA}${itemHref}`

        const breadcrumbs = sitemapToBreadcrumbs(item?.path)

        // console.log(item, imageMeta, tags)
        return {
            meta: {
                title: googleTitle || deviceTitle,
                description,
                canonical: url,
                openGraph: {
                    url,
                    title: socialTitleInput || deviceTitle,
                    description,
                    type: 'video.other',
                    images: processOpenGraphImages(imageMeta, socialTitleInput || deviceTitle || googleTitle || title),
                    updateTime: updateDate
                },
                twitter: {
                    creator: author
                },
                robotsProps: {
                    maxImagePreview: 'large',
                    maxVideoPreview: 10
                }
            },
            structuredData: [
                {
                    '@context': 'https://schema.org',
                    ...organizationStructuredData
                },
                {
                    '@context': 'https://schema.org',
                    '@type': 'WebPage',
                    name: googleTitle || deviceTitle,
                    publisher: organizationStructuredData
                },

                breadcrumbs && {
                    '@context': 'https://schema.org',
                    '@type': 'BreadcrumbList',
                    name: title,
                    description,
                    itemListElement: breadcrumbs
                },
                {
                    '@context': 'https://schema.org',
                    ...generateVideoStructuredData(item, imageMeta?.[0]?.url),
                    mainEntityOfPage: `${process.env.NEXT_PUBLIC_APP_ROOT_ZENA}${itemHref}`,
                    name: googleTitle || deviceTitle,
                    ...(structuredDataOverwrite || {})
                }
            ].filter(Boolean),
            href: `${process.env.NEXT_PUBLIC_APP_ROOT_ZENA}${itemHref}`
        }
    }, [item, deviceType, shareUrl, imageMeta, structuredDataOverwrite])
    return (
        <>
            {meta && meta.openGraph?.images?.[0] && <NextSeo {...meta} />}
            {structuredData && <StructuredData data={structuredData} />}
        </>
    )
}

Seo.propTypes = {
    item: PropTypes.oneOfType([() => null, PropTypes.object]),
    structuredDataOverwrite: PropTypes.oneOfType([() => null, PropTypes.object]),
    images: PropTypes.oneOfType([
        () => null,
        PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]))
    ]),
    hasAmp: PropTypes.bool,
    shareUrl: PropTypes.string
}

Seo.defaultProps = {
    item: undefined,
    structuredDataOverwrite: undefined,
    images: undefined,
    hasAmp: false,
    shareUrl: undefined
}

export { generateVideoStructuredData }
export default withErrorBoundary(Seo, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[VideoSeo]: ', error, componentStack)
    }
})
