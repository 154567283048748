// @TODO: revisit props when real data is connected
// @TODO: adjust icon color
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { useData } from '@hmn/rtl-web-core/hooks'

import isNumeric from '../../../../../helpers/utils/isNumeric'
import { Icon, UnionIcon } from '../../../../Icon'
import { Image, imageRatioVariants } from '../../../../Image'
import styles from './Item.style'

const PollItemStyled = styled.div(props => ({ ...styles(props) }))

function PollItem({ title, radioGroup, name, image, className, pollVariant, votePercentage, isSelected, ...rest }) {
    const { data: pollImage } = useData({
        resource: `image/${image?.id}`,
        enabled: !!image?.id
    })

    if (!radioGroup && !name) {
        return null
    }

    return (
        <PollItemStyled
            className={className}
            pollVariant={pollVariant}
            votePercentage={votePercentage}
            isSelected={isSelected}
            {...rest}>
            <div className="pollItem">
                {['beta', 'epsilon'].includes(pollVariant) && (
                    <div className="pollItem_image">
                        <Image
                            className="pollItem_image"
                            variation={imageRatioVariants.CUSTOM_PORTRAIT}
                            image={pollImage}
                            width={230}
                            // height={300}
                            fullHeight
                        />
                    </div>
                )}
                {votePercentage || title ? (
                    <div className="pollItem_info">
                        <div className="pollItem_info_head">
                            <div className="pollItem_info_head_title">{title}</div>
                            {isNumeric(votePercentage) ? (
                                <span className="pollItem_info_head_vote">{votePercentage}%</span>
                            ) : (
                                <div className="pollItem_select">
                                    <Icon className="pollItem_select_item" icon={UnionIcon} size={18} />
                                </div>
                            )}
                        </div>
                        {!!votePercentage && (
                            <div className="pollItem_info_votes">
                                <div className="pollItem_info_votes_bar" />
                            </div>
                        )}
                    </div>
                ) : (
                    <div className="pollItem_select">
                        <Icon className="pollItem_select_item" icon={UnionIcon} size={18} />
                    </div>
                )}
            </div>
        </PollItemStyled>
    )
}

PollItem.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    radioGroup: PropTypes.string,
    name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    image: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    pollVariant: PropTypes.string,
    votePercentage: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    isSelected: PropTypes.bool
}

PollItem.defaultProps = {
    className: undefined,
    title: undefined,
    radioGroup: undefined,
    name: undefined,
    image: undefined,
    pollVariant: 'alpha',
    votePercentage: undefined,
    isSelected: undefined
}

export default PollItem
