import PropTypes from 'prop-types'

export const instancePropTypes = {
    src: PropTypes.string.isRequired,
    isPost: PropTypes.bool,
    isVideo: PropTypes.bool
}
export const instanceDefaultProps = {
    isPost: undefined,
    isVideo: undefined
}
export const propTypes = {
    ...instancePropTypes,
    appId: PropTypes.string.isRequired,
    appRootUrl: PropTypes.string.isRequired
}

export const defaultProps = {
    ...instanceDefaultProps
}
