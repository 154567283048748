import { toRem, toRems } from '../../../../../helpers/theme'

const style = ({ theme, pollVariant, votePercentage, isSelected }) => {
    // @TODO: optimize config
    const config = {
        md: {
            pollVariant: {
                gamma: {
                    width: '48%'
                }
            }
        },
        pollVariant: {
            epsilon: {
                height: '100%'
                //     flexShrink: 0
            }
        },
        content: {
            pollVariant: {
                epsilon: {
                    position: 'relative',
                    flexDirection: 'column'
                }
            }
        },
        imageWrapper: {
            pollVariant: {
                beta: {
                    width: toRem(60)
                },
                epsilon: {
                    minHeight: toRem(395)
                }
            }
        },
        info: {
            pollVariant: {
                beta: {
                    width: 'calc(100% - 60px)'
                },
                epsilon: {
                    height: '15%'
                }
            },
            head: {
                xs: {
                    pollVariant: {
                        alpha: {
                            padding: toRems([0, 15, 0, 20])
                        },
                        beta: {
                            padding: toRems([0, 15, 0, 20])
                        },
                        gamma: {
                            padding: toRems([0, 15, 0, 20])
                        },
                        delta: {
                            padding: toRems([0, 15, 0, 20])
                        },
                        epsilon: {
                            padding: toRems([0, 15, 0, 10])
                        },
                        zeta: {
                            padding: toRems([0, 15, 0, 20])
                        }
                    }
                },
                md: {
                    pollVariant: {
                        alpha: {
                            padding: toRems([0, 15, 0, 20])
                        },
                        beta: {
                            padding: toRems([0, 15, 0, 20])
                        },
                        gamma: {
                            padding: toRems([0, 15, 0, 20])
                        },
                        delta: {
                            padding: toRems([0, 15, 0, 20])
                        },
                        epsilon: {
                            padding: toRems([0, 15, 0, 20])
                        },
                        zeta: {
                            padding: toRems([0, 15, 0, 20])
                        }
                    }
                }
            }
        },
        itemSelect: {
            pollVariant: {
                alpha: {
                    backgroundColor: theme.colors.poll.item.check.background.default
                },
                beta: {
                    backgroundColor: theme.colors.poll.item.check.background.default
                },
                gamma: {
                    backgroundColor: theme.colors.poll.item.check.background.default
                },
                delta: {
                    backgroundColor: theme.colors.poll.item.check.background.default
                },
                epsilon: {
                    backgroundColor: theme.colors.poll.item.check.background.image,
                    top: toRem(20),
                    right: toRem(20),
                    position: 'absolute',
                    fill: theme.colors.poll.item.check.background.alternative
                },
                zeta: {
                    backgroundColor: theme.colors.poll.item.check.background.image,
                    top: toRem(20),
                    right: toRem(20),
                    position: 'absolute',
                    fill: theme.colors.poll.item.check.background.alternative
                }
            }
        }
    }
    return {
        backgroundColor: theme.colors.poll.item.background,
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: config?.md?.pollVariant[pollVariant]?.width || '100%'
        },
        height: config?.pollVariant[pollVariant]?.height || toRem(60),
        margin: toRems([0, 10, 15, 0]),
        flexShrink: config?.pollVariant[pollVariant]?.flexShrink,
        '& .pollItem': {
            display: 'flex',
            alignItems: 'center',
            height: '100%',
            position: config?.content?.pollVariant[pollVariant]?.position,
            flexDirection: config?.content?.pollVariant[pollVariant]?.flexDirection,
            '&:hover': {
                cursor: 'pointer'
            },
            '&_image': {
                // minHeight: config?.imageWrapper?.pollVariant[pollVariant]?.height,
                height: '100%',
                width: config?.imageWrapper?.pollVariant[pollVariant]?.width || '100%',
                img: {
                    margin: 0,
                    ...(pollVariant !== 'epsilon' && {
                        padding: 0,
                        height: toRem(60)
                    })
                }
            },
            '&_info': {
                height: config?.info?.pollVariant[pollVariant]?.height || '100%',
                width: config?.info?.pollVariant[pollVariant]?.width || '100%',
                '&_head': {
                    display: 'flex',
                    alignItems: 'center',
                    height: toRem(60),
                    padding: config?.info?.head?.xs?.pollVariant[pollVariant]?.padding,
                    justifyContent: 'space-between',
                    fontSize: toRem(14),
                    lineHeight: 1.2,
                    [theme.breakpoints.up('md')]: {
                        fontSize: toRem(16),
                        padding: config?.info?.head?.md?.pollVariant[pollVariant]?.padding
                    },
                    '&_vote': {
                        fontWeight: theme.typography.fontWeight.bold
                    },
                    '&_title': {
                        ...(pollVariant === 'beta' && { maxWidth: '85%' }),
                        ...(!['beta', 'delta'].includes(pollVariant) && { maxWidth: '90%' })
                    }
                },
                '&_votes': {
                    width: '100%',
                    backgroundColor: 'white',
                    '&_bar': {
                        background: `linear-gradient(270deg,
                                    ${theme.colors.poll.item.response.default} 2%,
                                    ${theme.colors.poll.item.response.alternative} 97.33%)`,
                        width: `${votePercentage}%`,
                        height: toRem(5)
                    }
                }
            },
            '&_select': {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexShrink: 0,
                borderRadius: '50%',
                width: toRem(24),
                height: toRem(24),
                position: config?.itemSelect?.pollVariant[pollVariant]?.position,
                top: config?.itemSelect?.pollVariant[pollVariant]?.top,
                right: config?.itemSelect?.pollVariant[pollVariant]?.right,
                backgroundColor: isSelected
                    ? theme.colors.poll.item.check.background.selected
                    : config?.itemSelect?.pollVariant[pollVariant]?.backgroundColor,
                '&_item': {
                    '& path': {
                        fill: config?.itemSelect?.pollVariant[pollVariant]?.fill
                    }
                }
            }
        }
    }
}

export default style
