import clsx from 'clsx'
import { useAmp } from 'next/amp'
import dynamic from 'next/dynamic'
import PropTypes from 'prop-types'

import { AmpInstagram } from '../../components/Amp'
import { AntiClsFrame } from '../../components/AntiClsFrame'
import { EmbedSocial } from '../../components/HtmlContent/HtmlContent.style'
import { RenderInView } from '../../components/RenderInView'

const InstagramPostController = dynamic(
    // eslint-disable-next-line import/extensions
    () => import('./InstagramPost.controller'),
    { ssr: false }
)

export function CommonInstagramEmbed({ url }) {
    let postId
    const isAmp = useAmp()

    const regExp = /\/p\/(.*?)\//
    const match = url.match(regExp)
    // @NOTE: this are problematic url's, hence the manual extract
    // https://www.instagram.com/tv/CJWCZZYDwu9/?utm_source=ig_web_copy_link
    // https://www.instagram.com/reel/COwAFP1HfU8/?utm_source=ig_web_copy_link
    // https://www.instagram.com/p/CTMWUhRln4c/?utm_source=ig_web_copy_link
    if (!match?.[1]) {
        let indexOfTv
        if (url.indexOf('tv') !== -1) {
            indexOfTv = url.indexOf('tv') + 4
        } else if (url.indexOf('reel') !== -1) {
            indexOfTv = url.indexOf('reel') + 5
        } else {
            indexOfTv = url.indexOf('p/') + 1
        }
        if (indexOfTv !== -1) {
            const indexOfSlash = url.slice(indexOfTv)?.indexOf('/')

            if (indexOfSlash !== -1) {
                postId = url.slice(indexOfTv, indexOfTv + indexOfSlash)
            }
        }
    }
    postId = postId || match?.[1]

    if (!postId) {
        return null
    }

    return (
        <EmbedSocial className={clsx(!isAmp && 'u-paddingHeight')}>
            {isAmp ? (
                <AmpInstagram id={postId} width="400" height="400" layout="responsive" />
            ) : (
                <AntiClsFrame ratio={3 / 5} maxWidthXs="500px">
                    <RenderInView>
                        <InstagramPostController postId={postId} />
                    </RenderInView>
                </AntiClsFrame>
            )}
        </EmbedSocial>
    )
}

CommonInstagramEmbed.propTypes = {
    url: PropTypes.string
}

CommonInstagramEmbed.defaultProps = {
    url: undefined
}
