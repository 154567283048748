import { toRem } from '../../../../helpers/theme'

/* eslint-disable quote-props, no-dupe-keys, max-len */
const style = ({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    '& .videoMeta_inner': {
        marginRight: toRem(10),
        flexShrink: 0
    },
    '& .videoMeta_author': {
        fontSize: toRem(14),
        lineHeight: 1.4,
        fontWeight: theme.typography.fontWeight.bold,
        [theme.breakpoints.up('md')]: {
            fontSize: toRem(16)
        }
    },
    '& .videoMeta_date': {
        color: theme.colors.text.alternative,
        fontSize: toRem(16),
        [theme.breakpoints.down('sm')]: {
            lineHeight: 1.4,
            fontSize: toRem(14)
        }
    },
    '& .videoMeta_graphic': {
        width: '100%'
    }
})

export default style
